<mat-card class="content-wrapper">
    <mat-card-header class="title_center">
     Laser Maintenance
    </mat-card-header>
    <mat-card-content>
        <div>
            <a class="btn btn-info btn-sm" title="" data-toggle="modal" data-target="#confirmmaintain"><span>Maintanance Page</span></a>
            <a class="btn btn-info btn-sm" title="" (click)="Addapi(2)"><span>Maintanance completed</span></a>
            <a class="btn btn-danger btn-sm" title=""(click)="Addapi(0)"><span>Maintanance Remove</span></a>
        </div>

    </mat-card-content>
</mat-card>

<div id="confirmmaintain" class="modal fade" role="dialog" aria-labelledby="confirmmaintain" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <span>Confirm</span>
                <button type="button" class="close" data-dismiss="modal">
                    <div class="close_new"><i class="fa fa-times-circle"></i> </div>
                </button>
            </div>

            <div class="modal-body">
                <div class="popup_form_row" >
                 <p>Are you sure you want to add this site into Maintanance !</p>
                        <div class="popup_col_12 button-row">
                            <button mat-raised-button color="primary" (click)="Addapi(1)" 
                                type="submit">OK</button>
                            
                        </div>
                  
                </div>
            </div>
        </div>
    </div>
</div>