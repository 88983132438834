<mat-card class="content-wrapper">
    <mat-card-header class="title_center">
        <a class="btn btn-info btn-sm btn-sm" data-toggle="modal" style="float: left;" data-target="#agentmodal"
            title="Add Fancy Details"><i class="fa fa-plus"></i>
        </a>
        Agent List
        <a class="btn btn-warning btn-sm" style="float: right;" title="Reload Fancy Records" (click)="getAgentList()"><i
                class="fa fa-refresh fa-spin"></i>
        </a>
    </mat-card-header>
    <mat-card-content>
        <div class="row">
            <div class="col-sm-3 col-xs-3 p-10">
                <select class="form-control" [(ngModel)]="selectedUserType" (ngModelChange)="getAgentList()">
                    <option *ngFor="let option of options" [value]="option.userType">{{option.type}}</option>
                </select>
            </div>
        </div>
        <hr>
        <div class="table-responsive">
            <table class="table table-striped table-bordered table-sm row-border hover" id="fancytable" datatable
                [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead>
                    <tr class="heading">
                        <th>Type</th>
                        <th>Username</th>
                        <th>Whatsapp</th>
                        <th>Messanger</th>
                        <th>Instagram</th>
                        <th>Telegram</th>
                        <th>Phone Number</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let agent of agentList;let index=index;trackBy:trackagentid">
                        <td>{{agent.type}}</td>
                        <td>{{agent.userName}}</td>
                        <td>{{agent.whatsapp}}</td>
                        <td>{{agent.messanger}}</td>
                        <td>{{agent.instagram}}</td>
                        <td>{{agent.telegram}}</td>
                        <td>{{agent.phoneno}}</td>                        
                        <td>
                            <a class="btn btn-info btn-sm" title="Edit Agent Details"  data-toggle="modal" data-target="#editagentmodal" (click)="setSelectedAgent(agent)"><span><i
                                        class="fa fa-edit" aria-hidden="true"></i></span>
                            </a>
                            <a class="btn btn-info btn-sm" title="Delete Agent"  data-toggle="modal" data-target="#deleteagentmodal" (click)="setSelectedAgent(agent)"><span><i
                                class="fa fa-trash" aria-hidden="true"></i></span>
                    </a>
                        </td>
                    </tr>

                </tbody>
            </table>

        </div>
        <!-- Agent Add -->
        <div id="agentmodal" class="modal fade" role="dialog" aria-labelledby="agentmodal" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <span>Agent Details</span>
                        <button type="button" class="close" data-dismiss="modal">
                            <div class="close_new"><i class="fa fa-times-circle"></i> </div>
                        </button>
                    </div>

                    <div class="modal-body">
                        <div class="popup_form_row" *ngIf="AddAgentForm">
                            <form [formGroup]="AddAgentForm" (ngSubmit)="addAgent()">
                                <div>
                                    <label> Agent Name : </label>
                                    <input type="text" class="form-control" formControlName="userName">
                                </div>
                                <div>
                                    <label> Agent Type : </label>
                                    <select class="form-control" formControlName="userType">
                                        <option *ngFor="let option of options" [value]="option.userType">{{option.type}}</option>
                                    </select>
                                </div>
                                <div>
                                    <label> Whatsapp : </label>
                                    <input type="text" class="form-control" formControlName="whatsapp">
                                </div>
                                <div>
                                    <label> Messanger : </label>
                                    <input type="text" class="form-control" formControlName="messanger">

                                </div>
                                <div>
                                    <label> Instagram : </label>
                                    <input type="text" class="form-control" formControlName="instagram">

                                </div>
                                <div>
                                    <label> Telegram : </label>
                                    <input type="text" class="form-control" formControlName="telegram">

                                </div>
                                <div>
                                    <label> Phone Number : </label>
                                    <input type="text" class="form-control" formControlName="phoneno">
                                </div>
                                <div class="popup_col_12 button-row">
                                    <button mat-raised-button color="primary" [disabled]="AddAgentForm.invalid"
                                        type="submit">Add</button>
                                    <button mat-raised-button color="primary" data-dismiss="modal" (click)="resetForm()"
                                        type="button">Cancel</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

          <!-- Edit Agent -->
          <div id="editagentmodal" class="modal fade" role="dialog" aria-labelledby="editagentmodal" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <span>Edit Agent Details</span>
                        <button type="button" class="close" data-dismiss="modal">
                            <div class="close_new"><i class="fa fa-times-circle"></i> </div>
                        </button>
                    </div>

                    <div class="modal-body">
                        <div class="popup_form_row" *ngIf="EditAgentForm">
                            <form [formGroup]="EditAgentForm" (ngSubmit)="editAgent()">
                                <div>
                                    <label> Whatsapp : </label>
                                    <input type="text" class="form-control" formControlName="whatsapp">
                                </div>
                                <div>
                                    <label> Messanger : </label>
                                    <input type="text" class="form-control" formControlName="messanger">
                                </div>
                                <div>
                                    <label> Instagram : </label>
                                    <input type="text" class="form-control" formControlName="instagram">

                                </div>
                                <div>
                                    <label> Telegram : </label>
                                    <input type="text" class="form-control" formControlName="telegram">

                                </div>
                                <div>
                                    <label> Phone Number : </label>
                                    <input type="text" class="form-control" formControlName="phoneno">
                                </div>
                                <div class="popup_col_12 button-row">
                                    <button mat-raised-button color="primary" [disabled]="EditAgentForm.invalid"
                                        type="submit">Update</button>
                                    <button mat-raised-button color="primary" data-dismiss="modal" (click)="resetForm()"
                                        type="button">Cancel</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Delete all Confirm Info -->
        <div id="deleteagentmodal" class="modal fade" role="dialog" aria-labelledby="deleteagentmodal"
            aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <span>Confirm Box</span>
                        <button type="button" class="close" data-dismiss="modal">
                            <div class="close_new"><i class="fa fa-times-circle"></i> </div>
                        </button>
                    </div>

                    <div class="modal-body">
                        <div class="popup_form_row">
                            <label>Are you sure you want to delete this agent ?</label>
                        </div>
                        <div class="popup_col_12 button-row">
                            <button mat-raised-button color="primary" type="button" (click)="deleteAgent()">Yes</button>
                            <button mat-raised-button color="primary" data-dismiss="modal" type="button">No</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </mat-card-content>
</mat-card>