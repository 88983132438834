<mat-card class="content-wrapper">
    <mat-card-header class="title_center">
        Details
    </mat-card-header>
    <mat-card-content>
        <a class="btn btn-info btn-xs" data-toggle="modal" data-target="#usermodal" title="Create User"
            (click)="createusermodal()"><span>Create
                New</span>
        </a>
        <a class="btn btn-danger btn-xs" data-toggle="modal" data-target="#Confirmmodal"
            title="Delete All Users"><span>Delete
                All Users</span>
        </a>
        <hr>
        <div class="table-responsive">
            <table class="table table-striped table-bordered table-sm row-border hover" datatable
                [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead>
                    <tr class="heading">
                        <th>Sr.No</th>
                        <th>Domain Name</th>
                        <th>IP</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>TV</th>
                        <th>Score</th>
                        <th>Odds</th>
                        <th>Fancy</th>
                        <th>BM</th>
                        <th>Other Markets</th>
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>

                    <tr *ngFor="let user of userinfo;let index=index">
                        <td>{{index+1}}
                        </td>

                        <td>{{user.domain_name}}&nbsp;
                            <i class="fa fa-user fa_custom" title="Msg Locked" aria-hidden="true" style="color:red;"
                                *ngIf="user.status==0"></i>
                        </td>
                        <td style="padding-bottom: 0px;"><span class="m-bg">
                                <a>{{user.ip}}</a>
                            </span>
                        </td>
                        <td>{{dateformat(user.start_date)}}</td>
                        <td>{{dateformat(user.End_date)}}</td>
                        <td>
                            <a class="btn btn-info btn-xs" data-toggle="modal" (click)="userFlagmodal(user)"
                                data-target="#userTV" title="Odds"><span>TV</span>
                            </a>
                        </td>
                        <td>
                            <a class="btn btn-info btn-xs" data-toggle="modal" (click)="userFlagmodal(user)"
                                data-target="#userScore" title="Odds"><span>Score</span>
                            </a>
                        </td>
                        <td>
                            <a class="btn btn-info btn-xs" data-toggle="modal" (click)="Oddsmodal(user)"
                                data-target="#userOdds" title="Odds"><span>Odds</span>
                            </a>
                        </td>
                        <td>
                            <a class="btn btn-info btn-xs" data-toggle="modal" (click)="userFlagmodal(user)"
                                data-target="#userFancy" title="Fancy"><span>Fancy</span>
                            </a>
                        </td>
                        <td>
                            <a class="btn btn-info btn-xs" data-toggle="modal" (click)="userFlagmodal(user)"
                                data-target="#userBm" title="Book making"><span>BM</span>
                            </a>
                        </td>
                        <td>
                            <a class="btn btn-info btn-xs" data-toggle="modal" (click)="userFlagmodal(user)"
                                data-target="#userOM" title="Book making"><span>Other Markets</span>
                            </a>
                        </td>
                        <td>
                            <a class="btn btn-success btn-xs" data-toggle="modal" *ngIf="user.status==1"
                                (click)="Updateflag('status',user)" title="Status">
                                <i class="fa fa-check" aria-hidden="true"></i>
                            </a>
                            <a class="btn btn-danger btn-xs" data-toggle="modal" *ngIf="user.status==0"
                                (click)="Updateflag('status',user)" title="Status">
                                <i class="fa fa-times" aria-hidden="true"></i>
                            </a>
                        </td>
                        <td>
                            <a class="btn btn-info btn-xs" title="Edit User" data-toggle="modal"
                                data-target="#usermodal" (click)="updateuserdata(user)"><i class="fa fa-edit fa-4"
                                    aria-hidden="true"></i>
                            </a>
                            <a class="btn btn-danger btn-xs" title="Delete User" data-toggle="modal"
                                (click)="userFlagmodal(user)" data-target="#Confirmmodalsingleuser"><span><i
                                        class="fa fa-trash" aria-hidden="true"></i></span>
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <!-- Userinfo -->
        <div id="usermodal" class="modal fade" role="dialog" aria-labelledby="usermodal" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <span>User Details</span><span>{{domainname}}</span>
                        <button type="button" class="close" data-dismiss="modal">
                            <div class="close_new"><i class="fa fa-times-circle"></i> </div>
                        </button>
                    </div>

                    <div class="modal-body">
                        <div class="popup_form_row" *ngIf="UserForm">
                            <form [formGroup]="UserForm" (ngSubmit)="adduser()">
                                <div>
                                    <label> Domain Name : </label>
                                    <input type="text" class="form-control" formControlName="domainname">
                                </div>
                                <div>
                                    <label> Ip : </label>
                                    <input type="text" class="form-control" formControlName="Ip">
                                </div>
                                <div>
                                    <label> Date From : </label>
                                    <input type="text" class="form-control" formControlName="start_date">
                                </div>
                                <div>
                                    <label> Date To : </label>
                                    <input type="text" class="form-control" formControlName="end_date">
                                </div>
                                <div class="popup_col_12 button-row">
                                    <button mat-raised-button color="primary" [disabled]="UserForm.invalid"
                                        type="submit" *ngIf="!isedit">Add</button>
                                    <button mat-raised-button color="primary" [disabled]="UserForm.invalid"
                                        type="submit" *ngIf="isedit">Update</button>
                                    <button mat-raised-button color="primary" data-dismiss="modal" (click)="resetForm()"
                                        type="button">Cancel</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Confirm Info -->
        <div id="Confirmmodal" class="modal fade" role="dialog" aria-labelledby="Confirmmodal" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <span>Confirm Box</span>
                        <button type="button" class="close" data-dismiss="modal">
                            <div class="close_new"><i class="fa fa-times-circle"></i> </div>
                        </button>
                    </div>

                    <div class="modal-body">
                        <div class="popup_form_row">
                            <label>Are you sure you want to delete suspended user data ?</label>
                        </div>
                        <div class="popup_col_12 button-row">
                            <button mat-raised-button color="primary" type="button"
                                (click)="deleteallusers()">Yes</button>
                            <button mat-raised-button color="primary" data-dismiss="modal" type="button">No</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Confirmmodalsingleuser Info -->
        <div id="Confirmmodalsingleuser" class="modal fade" role="dialog" aria-labelledby="Confirmmodalsingleuser"
            aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <span>Confirm Box</span>
                        <button type="button" class="close" data-dismiss="modal">
                            <div class="close_new"><i class="fa fa-times-circle"></i> </div>
                        </button>
                    </div>

                    <div class="modal-body">
                        <div class="popup_form_row">
                            <label>Are you sure you want to delete {{domainname}} data ?</label>
                        </div>
                        <div class="popup_col_12 button-row">
                            <button mat-raised-button color="primary" type="button"
                                (click)="deletesingleuser(userid)">Yes</button>
                            <button mat-raised-button color="primary" data-dismiss="modal" type="button">No</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Odds -->
        <div id="userOdds" class="modal fade" role="dialog" aria-labelledby="userOdds" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <span>Odds Sport Wise Source</span>
                        <button type="button" class="close" data-dismiss="modal">
                            <div class="close_new"><i class="fa fa-times-circle"></i> </div>
                        </button>
                    </div>

                    <div class="modal-body" *ngIf="OddsForm" style="overflow-y: auto;max-height: 400px;">
                        <form [formGroup]="OddsForm" (ngSubmit)="Updateoddsflag()">
                            <div>
                                <mat-card>
                                    <mat-card-header class="title_center card-header-padding">
                                        Cricket
                                    </mat-card-header>
                                    <mat-card-content class="card-content-modal">
                                        <mat-radio-group color="primary" formControlName="Cricket"
                                            aria-label="Select an Source">
                                            <mat-radio-button class="radio-button-spacing" value="1">Diamond
                                            </mat-radio-button>
                                            <mat-radio-button class="radio-button-spacing" value="2">Sky
                                            </mat-radio-button>
                                            <mat-radio-button class="radio-button-spacing" value="3">Satsport
                                            </mat-radio-button>
                                            <mat-radio-button class="radio-button-spacing" value="4">BetFair
                                            </mat-radio-button>
                                            <mat-radio-button class="radio-button-spacing" value="0">None
                                            </mat-radio-button>
                                        </mat-radio-group>
                                    </mat-card-content>
                                    <mat-card-header class="title_center card-header-padding">
                                        Soccer
                                    </mat-card-header>
                                    <mat-card-content class="card-content-modal">
                                        <mat-radio-group color="primary" formControlName="Soccer"
                                            aria-label="Select an Source">
                                            <mat-radio-button class="radio-button-spacing" value="1">Diamond
                                            </mat-radio-button>
                                            <mat-radio-button class="radio-button-spacing" value="2">Sky
                                            </mat-radio-button>
                                            <mat-radio-button class="radio-button-spacing" value="3">Satsport
                                            </mat-radio-button>
                                            <mat-radio-button class="radio-button-spacing" value="4">BetFair
                                            </mat-radio-button>
                                            <mat-radio-button class="radio-button-spacing" value="0">None
                                            </mat-radio-button>
                                        </mat-radio-group>
                                    </mat-card-content>
                                    <mat-card-header class="title_center card-header-padding">
                                        Tennis
                                    </mat-card-header>
                                    <mat-card-content class="card-content-modal">
                                        <mat-radio-group color="primary" formControlName="Tennis"
                                            aria-label="Select an Source">
                                            <mat-radio-button class="radio-button-spacing" value="1">Diamond
                                            </mat-radio-button>
                                            <mat-radio-button class="radio-button-spacing" value="2">Sky
                                            </mat-radio-button>
                                            <mat-radio-button class="radio-button-spacing" value="3">Satsport
                                            </mat-radio-button>
                                            <mat-radio-button class="radio-button-spacing" value="4">BetFair
                                            </mat-radio-button>
                                            <mat-radio-button class="radio-button-spacing" value="0">None
                                            </mat-radio-button>
                                        </mat-radio-group>
                                    </mat-card-content>
                                    <mat-card-header class="title_center card-header-padding">
                                        Horse
                                    </mat-card-header>
                                    <mat-card-content class="card-content-modal">
                                        <mat-radio-group color="primary" formControlName="Horse"
                                            aria-label="Select an Source">
                                            <mat-radio-button class="radio-button-spacing" value="3">Satsport
                                            </mat-radio-button>
                                            <mat-radio-button class="radio-button-spacing" value="0">None
                                            </mat-radio-button>
                                        </mat-radio-group>
                                    </mat-card-content>
                                    <mat-card-header class="title_center card-header-padding">
                                        Greyhound
                                    </mat-card-header>
                                    <mat-card-content class="card-content-modal">
                                        <mat-radio-group color="primary" formControlName="Greyhound"
                                            aria-label="Select an Source">
                                            <mat-radio-button class="radio-button-spacing" value="3">Satsport
                                            </mat-radio-button>
                                            <mat-radio-button class="radio-button-spacing" value="0">None
                                            </mat-radio-button>
                                        </mat-radio-group>
                                    </mat-card-content>
                                </mat-card>
                            </div>
                            <div class="popup_col_12 button-row">
                                <button mat-raised-button color="primary" type="submit">Update</button>
                                <button mat-raised-button color="primary" data-dismiss="modal"
                                    type="button">Cancel</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <!-- TV Odds -->
        <div id="userTV" class="modal fade" role="dialog" aria-labelledby="userTV" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <span>TV</span>
                        <button type="button" class="close" data-dismiss="modal">
                            <div class="close_new">
                                <i class="fa fa-times-circle"></i>
                            </div>
                        </button>
                    </div>

                    <div class="modal-body">
                        <div class="popup_form_row">
                            <mat-card>
                                <mat-card-header class="title_center card-header-padding">
                                    Sports
                                </mat-card-header>
                                <mat-card-content class="card-content-modal">
                                    <mat-checkbox class="radio-button-spacing" [(ngModel)]="TvCricket">Cricket
                                    </mat-checkbox>
                                    <mat-checkbox class="radio-button-spacing" [(ngModel)]="TvSoccer">Soccer
                                    </mat-checkbox>
                                    <mat-checkbox class="radio-button-spacing" [(ngModel)]="TvTennis">Tennis
                                    </mat-checkbox>
                                </mat-card-content>
                            </mat-card>
                        </div>
                        <div class="popup_col_12 button-row">
                            <button mat-raised-button color="primary" (click)="Updateflag('tv','')">Update</button>
                            <button mat-raised-button color="primary" data-dismiss="modal" type="button">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Score -->
        <div id="userScore" class="modal fade" role="dialog" aria-labelledby="userScore" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <span>Score</span>
                        <button type="button" class="close" data-dismiss="modal">
                            <div class="close_new"><i class="fa fa-times-circle"></i> </div>
                        </button>
                    </div>

                    <div class="modal-body">
                        <div class="popup_form_row">
                            <mat-card>
                                <mat-card-header class="title_center card-header-padding">
                                    Sports
                                </mat-card-header>
                                <mat-card-content class="card-content-modal">
                                    <mat-checkbox class="radio-button-spacing" [(ngModel)]="ScoreCricket">Cricket
                                    </mat-checkbox>
                                    <mat-checkbox class="radio-button-spacing" [(ngModel)]="ScoreSoccer">Soccer
                                    </mat-checkbox>
                                    <mat-checkbox class="radio-button-spacing" [(ngModel)]="ScoreTennis">Tennis
                                    </mat-checkbox>
                                </mat-card-content>
                            </mat-card>
                        </div>
                        <div class="popup_col_12 button-row">
                            <button mat-raised-button color="primary" (click)="Updateflag('score','')">Update</button>
                            <button mat-raised-button color="primary" data-dismiss="modal" type="button">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- BM Odds -->
        <div id="userFancy" class="modal fade" role="dialog" aria-labelledby="userFancy" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <span>Fancy</span>
                        <button type="button" class="close" data-dismiss="modal">
                            <div class="close_new"><i class="fa fa-times-circle"></i> </div>
                        </button>
                    </div>

                    <div class="modal-body">
                        <div class="popup_form_row">
                            <mat-card>
                                <mat-card-header class="title_center card-header-padding">
                                    Sources
                                </mat-card-header>
                                <mat-card-content class="card-content-modal">
                                    <mat-radio-group color="primary" [(ngModel)]="Fancyflag"
                                        aria-label="Select an Source">
                                        <mat-radio-button class="radio-button-spacing" value="0">None
                                        </mat-radio-button>
                                        <mat-radio-button class="radio-button-spacing" value="1">Diamond
                                        </mat-radio-button>
                                        <mat-radio-button class="radio-button-spacing" value="2">Sky
                                        </mat-radio-button>
                                        <mat-radio-button class="radio-button-spacing" value="3">Satsport
                                        </mat-radio-button>
                                    </mat-radio-group>
                                </mat-card-content>
                            </mat-card>
                        </div>
                        <div class="popup_col_12 button-row">
                            <button mat-raised-button color="primary" type="submit"
                                (click)="Updateflag('fancy','')">Update</button>
                            <button mat-raised-button color="primary" data-dismiss="modal" type="button">Cancel</button>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <!-- BM Odds -->
        <div id="userBm" class="modal fade" role="dialog" aria-labelledby="userBm" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <span>BM</span>
                        <button type="button" class="close" data-dismiss="modal">
                            <div class="close_new"><i class="fa fa-times-circle"></i> </div>
                        </button>
                    </div>

                    <div class="modal-body">
                        <div class="popup_form_row">
                            <mat-card>
                                <mat-card-header class="title_center card-header-padding">
                                    Sources
                                </mat-card-header>
                                <mat-card-content class="card-content-modal">
                                    <mat-radio-group color="primary" [(ngModel)]="Bmflag" aria-label="Select an Source">
                                        <mat-radio-button class="radio-button-spacing" value="0">None
                                        </mat-radio-button>
                                        <mat-radio-button class="radio-button-spacing" value="1">Diamond
                                        </mat-radio-button>
                                        <mat-radio-button class="radio-button-spacing" value="2">Sky
                                        </mat-radio-button>
                                        <mat-radio-button class="radio-button-spacing" value="3">Satsport
                                        </mat-radio-button>
                                    </mat-radio-group>
                                </mat-card-content>
                            </mat-card>
                        </div>
                        <div class="popup_col_12 button-row">
                            <button mat-raised-button color="primary" type="submit"
                                (click)="Updateflag('BM','')">Update</button>
                            <button mat-raised-button color="primary" data-dismiss="modal" type="button">Cancel</button>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <!-- OM Odds -->
        <div id="userOM" class="modal fade" role="dialog" aria-labelledby="userOM" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <span>Other Markets</span>
                        <button type="button" class="close" data-dismiss="modal">
                            <div class="close_new"><i class="fa fa-times-circle"></i> </div>
                        </button>
                    </div>

                    <div class="modal-body">
                        <div class="popup_form_row">
                            <mat-card>
                                <mat-card-header class="title_center card-header-padding">
                                    Sources
                                </mat-card-header>
                                <mat-card-content class="card-content-modal">
                                    <mat-radio-group color="primary" [(ngModel)]="OMflag" aria-label="Select an Source">
                                        <mat-radio-button class="radio-button-spacing" value="0">None
                                        </mat-radio-button>
                                        <mat-radio-button class="radio-button-spacing" value="1">Diamond
                                        </mat-radio-button>
                                        <mat-radio-button class="radio-button-spacing" value="2">Sky
                                        </mat-radio-button>
                                        <mat-radio-button class="radio-button-spacing" value="3">Satsport
                                        </mat-radio-button>
                                    </mat-radio-group>
                                </mat-card-content>
                            </mat-card>
                        </div>
                        <div class="popup_col_12 button-row">
                            <button mat-raised-button color="primary" type="submit"
                                (click)="Updateflag('OM','')">Update</button>
                            <button mat-raised-button color="primary" data-dismiss="modal" type="button">Cancel</button>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    </mat-card-content>
</mat-card>