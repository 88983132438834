<div class="vertical-overlay-menu">
    <div class="main-menu menu-fixed menu-light menu-accordion menu-shadow">
        <div class="leftPane">
            <ul id="MainMenu" class="navigation navigation-main">
                <li class="nav-item" *ngIf="userrole=='ALL'" (click)="inputSideNav.toggle()">
                    <a [routerLink]="['/home']"><i class="fa fa-desktop"></i><span data-i18n=""
                            class="menu-title">Dashboard</span></a>
                </li>
                <!-- <li class="nav-item has-sub">
                    <a><i class="fa fa-user"></i><span class="menu-title">Master</span></a>
                    <ul class="menu-content">
                        <li class="" (click)="inputSideNav.toggle()">
                            <a [routerLink]="['/balance']" class="menu-item">Balance</a>
                        </li>
                        <li class="" (click)="inputSideNav.toggle()">
                            <a [routerLink]="['/sport-match-setting','0']" class="menu-item">Import Rate</a>
                        </li>
                        <li class="" (click)="inputSideNav.toggle()">
                            <a [routerLink]="['/ticker']" class="menu-item">List of News</a>
                        </li>
                    </ul>
                </li> -->
                <li class="nav-item" *ngIf="userrole=='WHITE'" (click)="inputSideNav.toggle()">
                    <a [routerLink]="['/whitelist']"><i class="fa fa-file-text"></i>
                        <span class="menu-title">Whitelist</span></a>
                </li>
                <!-- <li class="nav-item" *ngIf="userrole=='WHITE'" (click)="inputSideNav.toggle()">
                    <a [routerLink]="['/laserwhitelist']"><i class="fa fa-file-text"></i>
                        <span class="menu-title">Laser Whitelist</span></a>
                </li>

                <li class="nav-item" *ngIf="userrole=='WHITE'" (click)="inputSideNav.toggle()">
                    <a [routerLink]="['/dreaminplaywhitelist']"><i class="fa fa-file-text"></i>
                        <span class="menu-title">Dream Whitelist</span></a>
                </li> -->

                <ng-container *ngIf="userrole=='TV' || userrole=='ALL'">
                    <li class="nav-item" (click)="inputSideNav.toggle()">
                        <a [routerLink]="['/scoresetup']"><i class="fa fa-file-text"></i>
                            <span class="menu-title">Score</span></a>
                    </li>
                </ng-container>
                <ng-container *ngIf="userrole=='FANCY' || userrole=='ALL'">
                    <li class="nav-item" (click)="inputSideNav.toggle()">
                        <a [routerLink]="['/fancysettle']"><i class="fa fa-file-text"></i>
                            <span class="menu-title">FS Mgmt</span></a>
                    </li>
                </ng-container>
                <!-- <ng-container *ngIf="userrole=='FANCY' || userrole=='ALL'">
                    <li class="nav-item" (click)="inputSideNav.toggle()">
                        <a [routerLink]="['/premiumsettle']"><i class="fa fa-file-text"></i>
                            <span class="menu-title">Premium Mgmt</span></a>
                    </li>
                </ng-container> -->
                <ng-container *ngIf="userrole=='FANCY' || userrole=='ALL'">
                    <li class="nav-item" (click)="inputSideNav.toggle()">
                        <a [routerLink]="['/tkfancysettle']"><i class="fa fa-file-text"></i>
                            <span class="menu-title">SKY FS Mgmt</span></a>
                    </li>
                </ng-container>
                <ng-container>
                    <li class="nav-item" (click)="inputSideNav.toggle()">
                        <a [routerLink]="['/agentmanage']"><i class="fa fa-file-text"></i>
                            <span class="menu-title">Agent Mgmt</span></a>
                    </li>
                </ng-container>
                <ng-container>
                    <li class="nav-item" *ngIf="userrole=='WHITE'" (click)="inputSideNav.toggle()">
                        <a [routerLink]="['/awcwhitelist']"><i class="fa fa-file-text"></i>
                            <span class="menu-title">AWC whitelist</span></a>
                    </li>
                </ng-container>
                <!-- <ng-container >
                    <li class="nav-item" (click)="inputSideNav.toggle()">
                        <a [routerLink]="['/pendingmarkets']"><i class="fa fa-file-text"></i>
                            <span class="menu-title">Pending Markets</span></a>
                    </li>
                </ng-container>
                <ng-container >
                    <li class="nav-item" (click)="inputSideNav.toggle()">
                        <a [routerLink]="['/pendingcasino']"><i class="fa fa-file-text"></i>
                            <span class="menu-title">Pending Casino</span></a>
                    </li>
                </ng-container> -->
                <ng-container *ngIf="userrole=='FANCY' || userrole=='ALL'">
                    <li class="nav-item" (click)="inputSideNav.toggle()">
                        <a [routerLink]="['/pendingfancy']"><i class="fa fa-file-text"></i>
                            <span class="menu-title">Pending Fancy</span></a>
                    </li>
                </ng-container>
                <ng-container *ngIf="getusertype==2 || getusertype==3">
                    <li class="nav-item" (click)="inputSideNav.toggle()">
                        <a [routerLink]="['/settlement']"><i class="fa fa-file-text"></i>
                            <span class="menu-title">Settlement</span></a>
                    </li>
                </ng-container>
                <ng-container *ngIf="getusertype == 2">
                    <li class="nav-item" (click)="inputSideNav.toggle()">
                        <a [routerLink]="['/management']"><i class="fa fa-file-text"></i>
                            <span class="menu-title">MM</span></a>
                    </li>
                </ng-container>
                <!-- <ng-container *ngIf="userrole=='FANCY' || userrole=='ALL'">
                    <li class="nav-item" (click)="inputSideNav.toggle()">
                        <a [routerLink]="['/amanfancysettle']"><i class="fa fa-file-text"></i>
                            <span class="menu-title">Aman FS Mgmt</span></a>
                    </li>
                </ng-container> -->
                <!-- <ng-container *ngIf="userrole=='ALL'">
                    <li class="nav-item"  (click)="inputSideNav.toggle()">
                        <a [routerLink]="['/StoreApi']"><i class="fa fa-file-text"></i>
                            <span class="menu-title">Store All_api</span></a>
                    </li>
                </ng-container> -->
                <!-- <ng-container *ngIf="userrole=='ALL'">
                    <li class="nav-item"  (click)="inputSideNav.toggle()">
                        <a [routerLink]="['/casinoresult']"><i class="fa fa-file-text"></i>
                            <span class="menu-title">Store Casino Results</span></a>
                    </li>
                </ng-container> -->


                <!-- <ng-container *ngIf="userrole=='ALL'">
                    <li class="nav-item"  (click)="inputSideNav.toggle()">
                        <a [routerLink]="['/lasermaintenance']"><i class="fa fa-file-text"></i>
                            <span class="menu-title">Laser Maintenance</span></a>
                    </li>
                </ng-container> -->
            </ul>
        </div>
    </div>
</div>