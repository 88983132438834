import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CookieService } from "ngx-cookie-service";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class TokenService {
  BASEURL1 = environment.BASEURL1;
  constructor(private cookieService: CookieService,
    private http: HttpClient) {}

  setToken(token) {
    this.cookieService.set("kanaka", token);
  }
  setuid(uid){
    this.cookieService.set("uid", uid);
  }
  getuid(){
    return this.cookieService.get("uid");
  }
  setMMToken(token) {
    this.cookieService.set("Authorization", token);
  }
  getMMToken() {
   return this.cookieService.get("Authorization");
  }
  getUserType() {
    return this.cookieService.get("UserType");
   }
  getToken() {
    return this.cookieService.get("kanaka");
  }
  removeToken() {
    this.cookieService.delete("kanaka");
  }

  setUsername(uname) {
    this.cookieService.set("Username", uname);
  }
  setuserdata(data){
    this.cookieService.set("Userdata", data);
  }
  getUsername() {
    return this.cookieService.get("Username");
  }
  removeUsername() {
    this.cookieService.delete("Username");
    this.cookieService.delete("Userdata");
  }

  gettoken(){
    return this.http.get(`${this.BASEURL1}/getToken`);
  }
}
