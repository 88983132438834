import { MediaMatcher } from '@angular/cdk/layout';
import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataTableDirective } from 'angular-datatables';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { AllapiService } from '../Services/allapi.service';
import { NotificationService } from '../Services/notification.service';
import { LaserserviceService } from './laserservice.service';

@Component({
  selector: 'app-laserwhitelist',
  templateUrl: './laserwhitelist.component.html',
  styleUrls: ['./laserwhitelist.component.scss']
})

export class LaserwhitelistComponent implements OnInit,OnDestroy,AfterViewInit {
  mobileQuery: MediaQueryList;
  @ViewChild(DataTableDirective, { static: true }) dtElement: DataTableDirective;
  newslist = [];
  UserForm: FormGroup;
  OddsForm: FormGroup;
  private _mobileQueryListener: () => void;
  date: string;
  todate:string
  userinfo: any;
  submitted: boolean=false;
  userid: any;
  isedit: boolean = false;
  selectedsport: any;
  whitelisteddetails:any;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private fb: FormBuilder,
    private notifyService: NotificationService,
    private Allapi :LaserserviceService
  ) {
      this.mobileQuery = media.matchMedia('(max-width: 600px)');
      this._mobileQueryListener = () => changeDetectorRef.detectChanges();
      this.mobileQuery.addListener(this._mobileQueryListener);
  }
  
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  Whitelisttabs:any[]=[
    {"name" : "Sport","uri":"spdata"},
    {"name" : "Races","uri":"racedata"},
    {"name" : "Exchgames","uri":"exchgdata"},
    {"name" : "TV streams","uri":"spstream"},
    {"name" : "Casino Streams","uri":"casinostream"}
  ]

  ngOnInit() {
    this.date = moment().format("YYYY-MM-DD")
    var new_date = moment(this.date, "YYYY-MM-DD").add(365*2, 'days').toDate();
    this.todate = moment(new_date).format("YYYY-MM-DD")
    console.log(this.todate)
     this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      responsive: true,
     };
    
    this.initUserForm();
    var listurl = this.Whitelisttabs[0];
    this.whitelist(listurl);
  }

  initUserForm() {
    this.UserForm = this.fb.group({
      domain: ['', Validators.required],
      ip: ['', Validators.required],
      expiryDate: [this.todate],
    });
  }

  createusermodal(value){
    this.isedit = false;
    console.log(value);
    this.selectedsport=value;
    this.resetForm();
  }

  updateuserdata(user) {
    this.isedit = true;
    var todate = moment(user.expiryDate).format("YYYY-MM-DD")
    this.UserForm.setValue({
      domain:user.domain,
      ip:user.ip,
      expiryDate:todate,
    })
  }

  adduser() {
    this.submitted = true;
    var spurl = this.Whitelisttabs.find(w=>w.name == this.selectedsport.name);
    if (this.UserForm.invalid) {
      return;
    }
    if (!this.isedit) {
      let userdata=this.UserForm.value
    this.Allapi.whitelist(spurl.uri,JSON.stringify(userdata)).subscribe((data) => {
        this.whitelist(spurl);
        $("[data-dismiss=modal]").trigger("click");
      this.notifyService.success(data);
    },
    (error) => {
      this.whitelist(spurl);
      $("[data-dismiss=modal]").trigger("click");
      this.notifyService.error("updated");
      console.log(error);
    }
    )
    } else {
      let userdata=this.UserForm.value;
      this.Allapi.whitelist(spurl.uri,JSON.stringify(userdata)).subscribe((data) => {
        this.whitelist(spurl);
        $("[data-dismiss=modal]").trigger("click");
        this.notifyService.success(data);
      },
      (error) => {
        this.whitelist(spurl);
        $("[data-dismiss=modal]").trigger("click");
        this.notifyService.error("updated");
        console.log(error);
      })
    }
    
  }

  dateformat(date) {
    var format=moment(date).format("YYYY-MM-DD")
    return format;
  }

  updateuserinfo() {
    
  }

  

  whitelist(url) {
    
    let sport = (url.tab)?this.Whitelisttabs.find(w=>w.name == url.tab.textLabel):this.Whitelisttabs.find(w=>w.name == url.name);
    this.selectedsport = sport;
    console.log(this.selectedsport);
    
    this.Allapi.list(sport.uri).subscribe((resp) => {
      this.userinfo = resp;
      this.rerender();
    })
  }

  deletemodal(value) {
    this.whitelisteddetails = value;
  }

  deletewhitelist() {
    var spurl = this.Whitelisttabs.find(w=>w.name == this.selectedsport.name);
    this.Allapi.deletewhitelist(spurl.uri,this.whitelisteddetails?.domain).subscribe((data) => {
      this.whitelist(spurl);
      $("[data-dismiss=modal]").trigger("click");
      this.notifyService.success(data);
    },
    (error) => {
      this.whitelist(spurl);
      $("[data-dismiss=modal]").trigger("click");
      this.notifyService.error("updated");
    }
    )
  }

  // deletesingleuser(id) {
  //   this.Allapi.DeleteSingleuser(id).subscribe((data) => {
  //     if (data.result.Message =="Data Deleted Successfully") {
  //       this.whitelist("");
  //       $("[data-dismiss=modal]").trigger("click");
  //       this.notifyService.success(data.result.Message);
  //     } else {
  //       this.notifyService.error(data.result.Message);
  //     }
  //   })
  // }

  resetForm() {
      this.initUserForm();
  }

  ngAfterViewInit() {
    this.dtTrigger.next();
  }

  ngOnDestroy() {
    this.dtTrigger.unsubscribe();
  }

  rerender() {
    if (this.dtElement && this.dtElement.dtInstance) {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        // Destroy the table first
        dtInstance.destroy();
        // Call the dtTrigger to rerender again
        this.dtTrigger.next();
      });
    }
  }

}
