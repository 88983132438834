import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { AllapiService } from '../Services/allapi.service';

@Component({
  selector: 'app-pendingmarkets',
  templateUrl: './pendingmarkets.component.html',
  styleUrls: ['./pendingmarkets.component.scss']
})
export class PendingmarketsComponent implements OnInit {

  @ViewChild(DataTableDirective, { static: true }) dtElement: DataTableDirective;
  
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  mymarket:any
  
  constructor(private allapi:AllapiService) { }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      responsive: true,
     
     };
     this.pendingmarket()
  }
  pendingmarket(){
    this.allapi.PendingMarkets().subscribe((res) =>{
      // console.log(res);
      this.mymarket =res
      this.rerender() 
    })
  }
  ngAfterViewInit() {
    this.dtTrigger.next();
  }

  ngOnDestroy() {
    this.dtTrigger.unsubscribe();
  }

  rerender() {
    if (this.dtElement && this.dtElement.dtInstance) {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        // Destroy the table first
        dtInstance.destroy();
        // Call the dtTrigger to rerender again
        this.dtTrigger.next();
      });
    }
  }

}
