import { MediaMatcher } from '@angular/cdk/layout';
import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataTableDirective } from 'angular-datatables';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { AllapiService } from '../Services/allapi.service';
import { NotificationService } from '../Services/notification.service';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss']
})
export class UserInfoComponent implements OnInit,OnDestroy,AfterViewInit {
  mobileQuery: MediaQueryList;
  @ViewChild(DataTableDirective, { static: true }) dtElement: DataTableDirective;
  newslist = [];
  UserForm: FormGroup;
  OddsForm: FormGroup;
  TvCricket = false;
  TvSoccer = false;
  TvTennis = false;
  ScoreCricket = false;
  ScoreSoccer = false;
  ScoreTennis = false;
  private _mobileQueryListener: () => void;
  date: string;
  todate:string
  userinfo: any;
  submitted: boolean=false;
  userid: any;
  Fancyflag: any;
  Bmflag: any;
  OMflag: any;
  userStatus: any;
  isedit: boolean = false;
  domainname: any;
  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private fb: FormBuilder,
    private notifyService: NotificationService,
    private Allapi :AllapiService
  ) {
      this.mobileQuery = media.matchMedia('(max-width: 600px)');
      this._mobileQueryListener = () => changeDetectorRef.detectChanges();
      this.mobileQuery.addListener(this._mobileQueryListener);
  }
  
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  oddsflags: any[] = [
    {"Diamond":0},
    {"Sky":0},
    {"Satsport":0},
    {"Betfair":0},
    {"None":0},
  ];

  Fancyflags: any[] = [
    {"Cricket":0},
    {"Soccer":0},
    {"Tennis":0},
  ];

  ngOnInit() {
    this.date = moment().format("YYYY-MM-DD")
    var new_date = moment(this.date, "YYYY-MM-DD").add(365, 'days').toDate();
    this.todate = moment(new_date).format("YYYY-MM-DD")
    console.log(this.todate)
     this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      responsive: true,
     
     };
    
    this.initUserForm();
    this.getuserlist();
  }

  initUserForm() {
    this.UserForm = this.fb.group({
      domainname: ['', Validators.required],
      Ip: ['', Validators.required],
      start_date: [this.date, Validators.required],
      end_date: [this.todate],
    });
  }

  createusermodal(){
    this.isedit = false;
    this.resetForm();
  }

  updateuserdata(user) {
    this.isedit = true;
    this.userid = user.id;
    var startdate = moment(user.start_date).format("YYYY-MM-DD")
    var todate = moment(user.End_date).format("YYYY-MM-DD")
    this.UserForm.setValue({
      domainname:user.domain_name,
      Ip:user.ip,
      start_date:startdate,
      end_date:todate,
    })
  }

  adduser() {
    this.submitted = true;

    if (this.UserForm.invalid) {
      return;
    }
    if (!this.isedit) {
      var data={
      "domain_name":this.UserForm.value.domainname,
      "ip":this.UserForm.value.Ip,
      "start_date":this.UserForm.value.start_date,
      "tv":{"TvCricket":0,"TvSoccer":0,"TvTennis":0},
      "score":{"ScoreCricket":0,"ScoreSoccer":0,"ScoreTennis":0},
      "odds":{"Cricket":0,"Soccer":0,"Tennis":0,"Horse":0,"Greyhound":0},
      "fancy":0,
      "BM":0,
      "status":1,
      "other_markets":0,
      "End_date":this.UserForm.value.end_date
    } 
    // console.log(data)
    this.Allapi.Adduser(data).subscribe((data) => {
      if (data.result.Message =="Data Stored Successfully") {
        this.getuserlist();
        $("[data-dismiss=modal]").trigger("click");
        this.notifyService.success(data.result.Message);
      } else {
        this.notifyService.error(data.result.Message);
      }
    })
    } else {
      var userdata={
      "domain_name":this.UserForm.value.domainname,
      "ip":this.UserForm.value.Ip,
      "start_date":this.UserForm.value.start_date,
      "End_date":this.UserForm.value.end_date
    }
    this.Allapi.UpdateuserInfo(this.userid,userdata).subscribe((data) => {
      if (data.result.Message =="User Details Updated Successfully") {
        this.getuserlist();
        $("[data-dismiss=modal]").trigger("click");
        this.notifyService.success(data.result.Message);
      } else {
        this.notifyService.error(data.result.Message);
      }
    })
    }
    
  }

  dateformat(date) {
    var format=moment(date).format("YYYY-MM-DD")
    return format;
  }

  updateuserinfo() {
    
  }

  Oddsmodal(user) {
    this.OddsForm = this.fb.group({
      Cricket: [(user.odds.Cricket).toString()],
      Soccer: [(user.odds.Soccer).toString()],
      Tennis: [(user.odds.Tennis).toString()],
      Greyhound: [(user.odds.Greyhound).toString()],
      Horse: [(user.odds.Horse).toString()],
      userid:[user.id]
    });
  }

  userFlagmodal(user) {
    this.userid = user.id;
    this.domainname = user.domain_name;
    this.TvCricket = (user.tv.TvCricket == 0) ? false : true;
    this.TvSoccer = (user.tv.TvSoccer == 0) ? false : true;
    this.TvTennis = (user.tv.TvTennis == 0) ? false : true;
    this.ScoreCricket = (user.score.ScoreCricket == 0) ? false : true;
    this.ScoreSoccer = (user.score.ScoreSoccer == 0) ? false : true;
    this.ScoreTennis = (user.score.ScoreTennis == 0) ? false : true;
    this.Fancyflag = (user.fancy).toString();
    this.Bmflag = (user.BM).toString();
    this.OMflag = (user.other_markets).toString();
  }

  getuserlist() {
    this.Allapi.GetUserlist().subscribe((resp) => {
      this.userinfo = resp.result;
      this.rerender();
    })
  }

  

  Updateoddsflag() {
    this.submitted = true;

    if (this.OddsForm.invalid) {
      return;
    }
    var data={
      "Cricket":this.OddsForm.value.Cricket,
      "Soccer":this.OddsForm.value.Soccer,
      "Tennis":this.OddsForm.value.Tennis,
      "Horse":this.OddsForm.value.Horse,
      "Greyhound":this.OddsForm.value.Greyhound,
      "flag":"Odds"
    }
    // console.log(data);
    this.Allapi.UpdateUserFlag(this.OddsForm.value.userid, data).subscribe((data) => {
      if (data.result.Message == "User Flag Updated Successfully") {
        this.getuserlist();
        $("[data-dismiss=modal]").trigger("click");
        this.notifyService.success(data.result.Message);
      } else {
        this.notifyService.error(data.result.Message);
      }
    })
  }

  Updateflag(type,user) {
    var data: { Cricket?: string; Soccer?: string; Tennis?: string; flag: string; value?: string; };
    // console.log(user.id)
    if (user.id) {
      this.userid = user.id;
      this.userStatus = user.status;
    }
    if (type == 'tv') {
         data={
          "Cricket":(this.TvCricket)?'1':'0',
          "Soccer":(this.TvSoccer)?'1':'0',
          "Tennis":(this.TvTennis)?'1':'0',
          "flag":"tv"
        }
    } else if (type == 'fancy') {
       data={
        "value":this.Fancyflag,
        "flag":"fancy"
      }
    }else if (type == 'BM') {
       data={
        "value":this.Bmflag,
        "flag":"bm"
      }
    }else if (type == 'OM') {
       data={
        "value":this.OMflag,
        "flag":"other_markets"
      }
    }else if (type == 'status') {
       data={
        "value":(this.userStatus==0)?'1':'0',
        "flag":"status"
      }
    }else {
      data={
          "Cricket":(this.ScoreCricket)?'1':'0',
          "Soccer":(this.ScoreSoccer)?'1':'0',
          "Tennis":(this.ScoreTennis)?'1':'0',
          "flag":"score"
        }
    }
    
    // console.log(data);
    this.Allapi.UpdateUserFlag(this.userid, data).subscribe((data) => {
      if (data.result.Message == "User Flag Updated Successfully") {
        this.getuserlist();
        $("[data-dismiss=modal]").trigger("click");
        this.notifyService.success(data.result.Message);
      } else {
        this.notifyService.error(data.result.Message);
      }
    })
  }

  deleteallusers() {
    this.Allapi.DeleteAlluser().subscribe((data) => {
      if (data.result.Message =="Data Deleted Successfully") {
        this.getuserlist();
        $("[data-dismiss=modal]").trigger("click");
        this.notifyService.success(data.result.Message);
      } else {
        this.notifyService.error(data.result.Message);
      }
    })
  }

  deletesingleuser(id) {
    this.Allapi.DeleteSingleuser(id).subscribe((data) => {
      if (data.result.Message =="Data Deleted Successfully") {
        this.getuserlist();
        $("[data-dismiss=modal]").trigger("click");
        this.notifyService.success(data.result.Message);
      } else {
        this.notifyService.error(data.result.Message);
      }
    })
  }

  resetForm() {
      this.initUserForm();
  }

  ngAfterViewInit() {
    this.dtTrigger.next();
  }

  ngOnDestroy() {
    this.dtTrigger.unsubscribe();
  }

  rerender() {
    if (this.dtElement && this.dtElement.dtInstance) {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        // Destroy the table first
        dtInstance.destroy();
        // Call the dtTrigger to rerender again
        this.dtTrigger.next();
      });
    }
  }

}
