import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AwcserviceService {
  private headers = new Headers({'Content-Type': 'application/x-www-form-urlencoded'});
  baseurl:any= 'http://3.9.88.93:99'
  constructor(private http:HttpClient) { }
  awclist(){
    return this.http.get(`${this.baseurl}/awclist`)
  }
  AddAwc(domain,agent,cert,apiUrl,betlimit){
   return this.http.get(`${this.baseurl}/awcactivate?domain=${domain}&agent=${agent}&cert=${cert}&apiurl=${apiUrl}&betlimit=${JSON.stringify(betlimit)}`,{ responseType: 'text'})
  }
  
  awcDelete(domain){
    return this.http.get(`${this.baseurl}/awcdelete?domain=${domain}`,{ responseType: 'text'})
  }
}
