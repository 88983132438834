<mat-card class="content-wrapper">
    <mat-card-header class="title_center">
        <a class="btn btn-info btn-sm btn-sm" data-toggle="modal" style="float: left;" data-target="#awcmodal"
        title="Add AWC"><i class="fa fa-plus"></i>
        </a>
        AWC
        <a class="btn btn-warning btn-sm" style="float: right;" title="Reload Fancy Records" (click)="getAwcList()"><i
                class="fa fa-refresh fa-spin"></i>
        </a>
    </mat-card-header>
    <mat-card-content>
        <div class="table-responsive">
            <table class="table table-striped table-bordered table-sm row-border hover" datatable
                [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead>
                    <tr class="heading">
                        <th>AgentId</th>
                        <th>Domain Name</th>
                        <th>Cert</th>
                        <th>API URL</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>

                    <tr *ngFor="let lists of awclists">
                        <td>{{lists.agentId}}
                        </td>

                        <td>{{lists.domainName}}&nbsp;
                            <!-- <i class="fa fa-user fa_custom" title="Msg Locked" aria-hidden="true" style=""
                                ></i> -->
                        </td>
                        <td style="padding-bottom: 0px;"><span class="m-bg">
                                <a>{{lists.cert}}</a>
                            </span>
                        </td>

                        <td style="padding-bottom: 0px;"><span class="m-bg">
                            <a>{{lists.apiUrl}}</a>
                        </span>
                        </td>
                        
                        <td>
                            <!-- <a class="btn btn-info btn-xs" title="Edit User" data-toggle="modal"
                                data-target="#awcmodal"><i class="fa fa-edit fa-4"
                                    aria-hidden="true"></i>
                            </a> -->
                            <a class="btn btn-danger btn-xs" title="Delete User" data-toggle="modal" 
                                data-target="#Confirmmodal" (click)="setSelecteddomain(lists.domainName)"><span><i
                                        class="fa fa-trash" aria-hidden="true"></i></span>
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>


        <!--add AWC   -->
        <div id="awcmodal" class="modal fade" role="dialog" aria-labelledby="awcmodal" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <span>Add Domain</span>
                        <button type="button" class="close" data-dismiss="modal">
                            <div class="close_new"><i class="fa fa-times-circle"></i> </div>
                        </button>
                    </div>

                    <div class="modal-body">
                        <div class="popup_form_row" *ngIf="AwcForm">
                            <form [formGroup]="AwcForm" (ngSubmit)="Addawc()">
                                <div>
                                    <label> Domain : </label>
                                    <input type="text" class="form-control" formControlName="domain">
                                </div>
                                <div>
                                    <label> Select Menu : </label>
                                    <select class="form-control" formControlName="selectmenu" (change)="changeAgent($event)">
                                        <option value="">Select an option</option>
                                        <option *ngFor="let agent of agentsMap" [value]="agent.id">{{agent.agent}} ({{agent.owner}}) </option>
                                    </select>
                                    <!-- <input type="text" class="form-control" formControlName="agentMap"> -->
                                </div>
                            
                                <div class="popup_col_12 button-row">
                                    <button mat-raised-button color="primary" [disabled]="AwcForm.invalid"
                                        type="submit" >Add</button>
                                    
                                    <button mat-raised-button color="primary" data-dismiss="modal" 
                                        type="button">Cancel</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- confirm --> 
        <div id="Confirmmodal" class="modal fade" role="dialog" aria-labelledby="Confirmmodal" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <span>Confirm Box</span>
                        <button type="button" class="close" data-dismiss="modal">
                            <div class="close_new"><i class="fa fa-times-circle"></i> </div>
                        </button>
                    </div>

                    <div class="modal-body">
                        <div class="popup_form_row">
                            <label>Are you sure you want to delete this record ?</label>
                        </div>
                        <div class="popup_col_12 button-row">
                            <button mat-raised-button color="primary" type="button" (click)="deletedomain()">Yes</button>
                            <button mat-raised-button color="primary" data-dismiss="modal" type="button">No</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>