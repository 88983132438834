import { Component, OnInit } from '@angular/core';
import { NotificationService } from 'src/app/Services/notification.service';
import { OlgaserviceService } from './olgaservice.service';

@Component({
  selector: 'app-olgacasino',
  templateUrl: './olgacasino.component.html',
  styleUrls: ['./olgacasino.component.scss']
})
export class OlgacasinoComponent implements OnInit {
  selectable:string="";
  roundId:string="";
  roundData:string="";
  roundResultdata:any;
  nonOlga:boolean=true;
  Tableslist = 
  [
    {type:1,tableid:1,name:"Amar Akbar Anthony"},
    {type:1,tableid:2,name:"Bollywood Table"},
    {type:1,tableid:3,name:"32 CARDS-A"},
    {type:1,tableid:4,name:"five Cricket"},
    {type:1,tableid:5,name:"20 20 Cricket"},
    {type:1,tableid:6,name:"Dragon Tiger"},
    {type:1,tableid:7,name:"Dragon Tiger 2"},
    {type:1,tableid:8,name:"Baccarat"},
    {type:1,tableid:9,name:"Baccarat 2"},
    {type:1,tableid:10,name:"Test Teenpatti"},
    {type:1,tableid:11,name:"Muflis Teenpatti"},
    {type:1,tableid:12,name:"Card 20 20"},
    {type:1,tableid:13,name:"Worli Matka"},
    
    {type:2,tableid:21,name:"Andar Bahar 2"},
    {type:2,tableid:22,name:"1 Day Dragon Tiger"},
    {type:2,tableid:24,name:"Lucky 7 A"},
    {type:2,tableid:26,name:"Teenpatti 2020"},
    {type:2,tableid:28,name:"32 Cards B"},
    {type:2,tableid:29,name:"1 Day Teenpatti"},
    {type:2,tableid:30,name:"Lucky 7 B"},
  ]

  constructor(private casinoapi :OlgaserviceService,private notifyService: NotificationService,) { }

  ngOnInit(): void {
  }

  storeResult(){
    if(!this.nonOlga){
      if(this.roundData==null || this.roundData==""){
        this.notifyService.success("Fill Round Data");
        return;
      }
      var data = this.roundData;
      this.casinoapi.storeOlgaresult(data).subscribe(resp=>{
          this.notifyService.success("Result Saved");
      },(error) => {                              //Error callback
        console.log('error caught in component');
        this.notifyService.success("Done");
        //throw error;   //You can also throw the error to a global error handler
      })
    }else{
      if(this.selectable==null || this.selectable==""){
        this.notifyService.success("Select table Name");
        return
      }
      if(this.roundId==null || this.roundId==""){
        this.notifyService.success("Fill Round Id");
        return
      }
      this.casinoapi.storeSMBresult(this.selectable+"."+this.roundId).subscribe(resp=>{
          console.log(resp);
          this.notifyService.success("Result Saved");
      },(error) => {                              //Error callback
        console.log('error caught in component');
        this.notifyService.success("Done");
        //throw error;   //You can also throw the error to a global error handler
      })
    }
  }

  dataChanged(event){
    //console.log(this.selectable,event);
    if(event > 20){
      this.nonOlga = true;
    }else{
      this.nonOlga = false;
    }
  }

  checkRoundResult(){
    console.log(this.selectable,this.roundId);
    
    if(this.selectable==null || this.selectable==""){
      this.notifyService.success("Select table Name");
      return
    }
    if(this.roundId==null || this.roundId==""){
      this.notifyService.success("Fill Round Id");
      return
    }
    this.casinoapi.getRoundresult(this.selectable+"."+this.roundId).subscribe((resp)=>{
      // console.log(resp);
      this.roundResultdata=resp;
    })
  }

  clearspace(){
    this.roundData="";
    this.roundId="";
    this.selectable="";
  }

  trckbytableid(index,table)
  {
    return table.tableid;
  }

}
