let hostname = window.origin;

export const environment = {
  production: true,
  BASEURL : "https://sportbet.id/VRN/v1/api",
  // BASEURL1 : "http://167.86.102.80:8061/api", //80 server db on 80
  BASEURL1 : "http://78.141.233.65:8061/api", //32 server db on 32
  APIURL:"https://tvlivestreaming.online:15692",
  APIURL2:" https://streamingtv.fun:3440",
  APIURL3:"https://api.laserbook247.com:15692",
  APIURL4:"http://78.141.230.81:8061/api",
  CASINOURL:"https://api.laserbook247.com:3440/",
  SMBCASINOURL:"https://api.laserbook247.com:3468"
};


if (hostname.indexOf('localhost') > -1) {
  environment.BASEURL1 = "http://78.141.233.65:8061/api"; //80 server db on 80;
} else if (hostname.indexOf('bkpanel') > -1) {
  if(hostname.includes('https://')){
    environment.BASEURL1="https://bkp.sportbet.id:8444/api"
  }else{
    environment.BASEURL1 = "http://78.141.233.65:8068/api"; 
  }
} else{
  if(hostname.includes('https://')){
    environment.BASEURL1="https://api.sportbet.id:8444/api"
  }else{
    environment.BASEURL1="http://167.86.102.80:8061/api"
  }
}