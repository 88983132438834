import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { AgentmanageService } from './agentmanage.service';
import { NotificationService } from '../Services/notification.service';

@Component({
  selector: 'app-agentmanage',
  templateUrl: './agentmanage.component.html',
  styleUrls: ['./agentmanage.component.scss']
})
export class AgentmanageComponent implements OnInit,OnDestroy,AfterViewInit {
  @ViewChild(DataTableDirective, { static: true }) dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  agentList:any=[];
  selectedAgent:any;
  AddAgentForm: FormGroup;
  EditAgentForm: FormGroup;
  options = [
    {
      userType: 1,
      type:"SUPER ADMIN"
    },
    {
      userType: 2,
      type:"ADMIN"
    },
    {
      userType: 3,
      type:"SENIOR SUB ADMIN"
    },
    {
      userType: 4,
      type:"SUB ADMIN"
    },
    {
      userType: 5,
      type:"SUPER"
    },
    {
      userType: 6,
      type:"MASTER"
    },
    {
      userType: 0,
      type:"SUPPORT"
    }
  ];
  selectedUserType: any = "1";
  constructor(private fb: FormBuilder,private allapi:AgentmanageService, private notifyService: NotificationService) { 
    $(document).on("focus", 'input[type="search"]', function () {
      var ele:any = $('input[type="search"]').val();
      var table = $("#fancytable").DataTable();
      table.search(ele, false, false, false).draw();
    });
  }

  ngOnInit(): void {
    this.getAgentList();
    this.initAgentForm();
    this.initEditAgentForm();
    this.initDatatable();
  }

  initAgentForm() {
    this.AddAgentForm = this.fb.group({
      userName: ['', Validators.required],
      userType: ['', Validators.required],
      whatsapp: [''],
      messanger: [''],
      instagram: [''],
      telegram: [''],
      phoneno:['']
    });
  }

  initEditAgentForm() {
    this.EditAgentForm = this.fb.group({
      userId: ['', Validators.required],
      whatsapp: [''],
      messanger: [''],
      instagram: [''],
      telegram: [''],
      phoneno:['']
    });    
  }

  initDatatable() {
    // console.log('table')
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 500,
      processing: true,
      responsive: true,
      deferRender: true,
      order:[[0, 'desc']]
    };
  }

  setSelectedAgent(agent:any){
    this.selectedAgent = agent;
    this.EditAgentForm.controls['userId'].setValue(agent.userId);
    this.EditAgentForm.controls['whatsapp'].setValue(agent.whatsapp);
    this.EditAgentForm.controls['messanger'].setValue(agent.messanger);
    this.EditAgentForm.controls['instagram'].setValue(agent.instagram);
    this.EditAgentForm.controls['telegram'].setValue(agent.telegram);
    this.EditAgentForm.controls['phoneno'].setValue(agent.phoneno);
  }

  ngAfterViewInit() {
    this.dtTrigger.next();
  }

  ngOnDestroy() {
    this.dtTrigger.unsubscribe();
    // this.checknewfancy.unsubscribe();
  }

  rerender() {
    if (this.dtElement && this.dtElement.dtInstance) {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        // Destroy the table first
        dtInstance.destroy();
        // Call the dtTrigger to rerender again
        this.dtTrigger.next();
      });
    }
  }

  getAgentList(){
    this.allapi.getAgentList(this.selectedUserType).subscribe(result => {
      if (result.status =="Success") {
       console.log(result.data);
       this.agentList = result.data;
       this.rerender()
      } else {
        this.notifyService.error(result.data);
      }
    })
  }

  deleteAgent(){
    this.allapi.deleteAgent(this.selectedAgent.userId).subscribe(result => {
      if (result.status =="Success") {
        this.getAgentList();
        this.notifyService.success(result.message);
        $("[data-dismiss=modal]").trigger("click");
      } else {
        this.notifyService.error(result.message);
      }
    })
  }

  editAgent(){
    this.allapi.updateAgent(this.EditAgentForm.value).subscribe(result => {
      if (result.status =="Success") {
        this.getAgentList();
        this.resetEditForm();
        this.notifyService.success(result.message);
        $("[data-dismiss=modal]").trigger("click");
      } else {
        this.notifyService.error(result.message);
      }
    })
  }

  addAgent(){
    console.log(this.AddAgentForm.value);
    this.allapi.AddAgent(this.AddAgentForm.value).subscribe(result => {
      if (result.status =="Success") {
        this.getAgentList();
        this.resetForm();
        this.notifyService.success(result.message);
        $("[data-dismiss=modal]").trigger("click");
      } else {
        this.notifyService.error(result.message);
      }
    })
  }

  resetForm() {
    this.AddAgentForm.reset();
  }

  resetEditForm() {
    this.EditAgentForm.reset();
  }

  trackagentid(index,agent)
  {
    return agent.userId;
  }
}
