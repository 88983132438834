import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class AllapiService {
  BASEURL = environment.BASEURL;
  BASEURL1 = environment.BASEURL1;
  APIURL=environment.APIURL;
  APIURL2= environment.APIURL2;
  APIURL3= environment.APIURL3;
  APIURL4= environment.APIURL4;

  constructor(private http: HttpClient) { }

  Addtv(data): Observable<any> {
    return this.http.post(`${this.BASEURL}/LiveTv/post`, data);
  }

  GetTvlist(): Observable<any> {
    return this.http.get(`${this.BASEURL}/livetv/getall`);
  }

  Getmatchwise(matchid): Observable<any> {
    return this.http.get(`${this.BASEURL}/livetv/get?matchid=${matchid}`);
  }

  DeleteAllscores(): Observable<any> {
    return this.http.delete(`${this.BASEURL}/scoreid/deleteall`);
  }

  Addscore(data): Observable<any> {
    return this.http.post(`${this.BASEURL}/scoreid/post`, data);
  }

  GetScorelist(): Observable<any> {
    return this.http.get(`${this.BASEURL}/scoreid/getall`);
  }

  DeleteSinglescores(eventid): Observable<any> {
    return this.http.delete(`${this.BASEURL}/scoreid/delete?eventid=${eventid}`);
  }

  Adduser(data): Observable<any> {
    return this.http.post(`${this.BASEURL}/userdetail/post`, data);
  }

  GetUserlist(): Observable<any> {
    return this.http.get(`${this.BASEURL}/userdetail/getall`);
  }

  DeleteSingleuser(id): Observable<any> {
    return this.http.delete(`${this.BASEURL}/userdetail/delete?id=${id}`);
  }

  DeleteAlluser(): Observable<any> {
    return this.http.delete(`${this.BASEURL}/userdetail/deleteall`);
  }

  UpdateuserInfo(id,data): Observable<any> {
    return this.http.put(`${this.BASEURL}/userdetail/UpdateUserDetails?id=${id}`, data);
  }
  
  UpdateUserFlag(id,data): Observable<any> {
    return this.http.put(`${this.BASEURL}/userdetail/UpdateUserFlag?id=${id}`, data);
  }
  highlights(id): Observable<any> {
    return this.http.get(`${this.APIURL}/api/highlights/${id}`);
  }
  Addticker(data): Observable<any> {
    return this.http.post(`${this.APIURL}/api/store_ticker`, data);
  }
  Getticker(): Observable<any> {
    return this.http.get(`${this.APIURL}/api/get_ticker`);
  }
  Addmatchdetails(data): Observable<any> {
    return this.http.post(`${this.APIURL}/api/store_match_data`, data);
  }
  Serverswitchapi(data) : Observable<any>{
    return this.http.post(`${this.APIURL}/api/store_all_apis`,data);
  }

  setCasinoRoundResult(roundId): Observable<any> {
    return this.http.get(`${this.APIURL}/api/setRoundResult/${roundId}`);
  }


  Getuserinfo(uname): Observable<any> {
    return this.http.get(`${this.BASEURL1}/Getuserinfo?username=${uname}`);
  }
  
  GetfancybyId(id): Observable<any> {
    return this.http.get(`${this.BASEURL1}/GetfancybyId/${id}`);
  }
  GetfancyStatus(status): Observable<any> {
    return this.http.get(`${this.BASEURL1}/GetfancyStatus/${status}`);
  }
  GetfancybyName(fancy): Observable<any> {
    return this.http.get(`${this.BASEURL1}/GetfancybyName/${fancy}`);
  }
  GetfancybyMatch(eventname): Observable<any> {
    return this.http.get(`${this.BASEURL1}/GetfancybyMatch/${eventname}`);
  }

  Mapfancy(data): Observable<any> {
    return this.http.post(`${this.APIURL}/api/store_map_bm_fancy`, data);
  }

  StoreApi(data): Observable<any> {
    return this.http.post(`${this.APIURL}/api/store_all_apis`, data);
  }
  GetAllapi(): Observable<any> {
    return this.http.get(`${this.APIURL2}/api/all_apis`);
  }


  AddLaserticker(data): Observable<any> {
    return this.http.post(`${this.APIURL3}/api/store_ticker`, data);
  }
  GetLaserticker(): Observable<any> {
    return this.http.get(`${this.APIURL3}/api/get_ticker`);
  }

  storemaintenance(data):Observable<any> {
    return this.http.post(`https://api.laserbook247.com:15692/api/store_all_apis`, data);
  }
    getAllmaintenance(): Observable<any> {
    return this.http.get(`https://api.laserbook247.com:3440/api/all_apis`);
  }

  PendingMarkets(){
    return this.http.get('https://api.magic365.live/api/sports/open-markets-results')
  }

  PendingCasino(){
    return this.http.get('https://api.magic365.live/api/sports/open-casino-markets-results')
  }
  pendingFancy(status): Observable<any> {
    return this.http.get(`${this.BASEURL1}/pendingFancy/${status}`);
  }

  
}
