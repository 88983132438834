import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { AllapiService } from '../Services/allapi.service';

@Component({
  selector: 'app-pendingcasino',
  templateUrl: './pendingcasino.component.html',
  styleUrls: ['./pendingcasino.component.scss']
})
export class PendingcasinoComponent implements OnInit {


  mycasino:any
  @ViewChild(DataTableDirective, { static: true }) dtElement: DataTableDirective;
  
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  
  constructor(private allapi:AllapiService) { }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      responsive: true,
     };
     this.pendingcasino()
  }

  pendingcasino(){
    this.allapi.PendingCasino().subscribe((res)=>{
      this.mycasino =res
      this.rerender() 
    })
    
  }
  ngAfterViewInit() {
    this.dtTrigger.next();
  }

  ngOnDestroy() {
    this.dtTrigger.unsubscribe();
  }

  rerender() {
    if (this.dtElement && this.dtElement.dtInstance) {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        // Destroy the table first
        dtInstance.destroy();
        // Call the dtTrigger to rerender again
        this.dtTrigger.next();
      });
    }
  }
}
