<mat-card class="content-wrapper">
    <mat-card-header class="title_center">
        Pending Fancy
        <a class="btn btn-warning btn-sm" style="float: right;" title="Reload Fancy Records"><i
            class="fa fa-refresh fa-spin"></i>
    </a>
    </mat-card-header>
    <mat-card-content>
        <div class="row">
            <div class="col-sm-3 col-xs-3 p-10">
                <select class="form-control"  [(ngModel)]="selectedStatus" (ngModelChange)="pendinfancy()" >
                    <option *ngFor="let option of options" [value]="option.status">{{option.type}}</option>
                </select>
            </div>
            </div>
            <hr>
    </mat-card-content>

    <div class="table-responsive">
        <table class="table table-striped table-bordered table-sm row-border hover" id="fancytable" datatable
            [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
            <thead>
                <tr class="heading">
                    <th>EventId</th>
                    <th>Event Name</th>
                    <th>gameId</th>
                    <th>Action</th>
                </tr>
            </thead>
          
            <tbody>
                <tr *ngFor="let market of fancymarket;let index=index">
                    <td>{{market.eventId}}</td>
                    <!-- <td>{{market.eventName}} / {{market.startTime.split("T")[0]}}</td> -->
                    <td style="text-align: center;font-weight: 700;">{{market.eventName}}<br><span
                        style="font-size: 10px">{{market.startTime.split("T")[0]}}</span></td>
                    <td>{{market.gameId}}</td>
                    <td>
                        <button *ngIf="selectedStatus=='0' || selectedStatus=='2'" class="btn btn-info btn-sm"[routerLink]="['/fancysettle',market.eventId]">view</button>
                        <button *ngIf="selectedStatus=='1'" class="btn btn-info btn-sm"[routerLink]="['/tkfancysettle',market.eventId]">view</button>
                    </td>
              </tr>
            </tbody>
        </table>
    </div>
</mat-card>