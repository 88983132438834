
<!-- loader -->
<div class="loading" *ngIf="loader">
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
</div>
<!-- loader -->
<mat-card class="content-wrapper">
  <mat-card-header class="title_center">
    Management
    <a class="btn btn-warning btn-sm" style="float: right;" title="Reload Records" (click)="onRefresh()"><i
        class="fa fa-refresh fa-spin"></i>
    </a>
  </mat-card-header>
  <mat-card-content>
    <mat-tab-group mat-align-tabs="start" (selectedTabChange)="selectTab($event)">
      <ng-container *ngFor="let type of Types;let index=index">
        <mat-tab label="{{type.name}}" (click)="selectTab(index)">
        </mat-tab>
      </ng-container>
    </mat-tab-group>
    <div class="col-sm-3 col-xs-3 p-10 d-inline-block">
      <select class="form-control" (change)="filterSport($event)">
        <option [value]="0" selected>ALL</option>
        <option [value]="1">Soccer</option>
        <option [value]="2">Tennis</option>
        <option [value]="4">Cricket</option>
        <option [value]="52">Kabaddi</option>
      </select>
      <!-- <input type="radio" name="events" id="events_0" data-eventtype="1" checked (change)="filterSport($event)"
      value="0" />
    <label for="events_0">{{Update?.All}}</label>
    <input type="radio" name="events" id="events_1" data-eventtype="1" (change)="filterSport($event)" value="1" />
    <label for="events_1">Soccer</label>
    <input type="radio" name="events" id="events_2" data-eventtype="2" (change)="filterSport($event)" value="2" />
    <label for="events_2">Tennis</label>
    <input type="radio" name="events" id="events_4" data-eventtype="4" (change)="filterSport($event)" value="4" />
    <label for="events_4">Cricket</label>
    <input type="radio" name="events" id="events_52" data-eventtype="52" (change)="filterSport($event)"
      value="52" />
    <label for="events_52">Kabaddi</label> -->
    </div>
    <div id="userSearchUl" class="search-wrap d-inline-block">
      <button id="searchUserId" class="search-but">Search : </button>
      <input class="search-input" appInputRestriction="noSpecialChars" type="text" name="userId" id="userId"
        placeholder="Search Events, Tournaments" [(ngModel)]="searchTerm"
        (keyup)="searchTerm$.next($event.target.value)" class="search-input" />
    </div>
    <div class="d-inline-block">
      <ul class="activate">
        <ng-container *ngIf="selectedTabIndex == 0; else deactivateTemp">
          <li class="activated-text">Activate selected :</li>
          <li class="activated-switch">
            <label class="xyz activated-checkbox">
              <input type="checkbox" id="activateGamesToggle" (change)="activatedCheck($event)" /><span
                class="slider round"></span></label>
          </li>
        </ng-container>
        <ng-template #deactivateTemp>
          <li *ngIf="showActions" class="activated-text">Deactivate selected:</li>
          <li *ngIf="showActions" class="activated-switch">
            <label class="xyz activated-checkbox">
              <input type="checkbox" id="deactivateGamesToggle" (change)="deactivateCheck($event)" checked />
              <span class="slider round"></span></label>
          </li>
        </ng-template>
      </ul>
    </div>
    <div class="d-inline-block">
      <button class="btn btn-info btn-sm" (click)="openliqmodal()"  data-toggle="modal" data-target="#isliquidityModalOpen">Set Liquidity</button>
    </div>
    <hr>
    <div class="table-responsive">

      <table class="table table-striped table-bordered table-sm row-border hover" *ngIf="selectedTabIndex === 0">
        <thead>
          <tr>
            <th rowspan="2">
              <input type="checkbox" [(ngModel)]="selectAll" (click)="toggleSelectAll($event.target.checked, 0)"
                name="selectAll" id="selectAll" />
            </th>
            <th class="header align-C" colspan="" rowspan="2">SportName</th>

            <th class="header align-C" colspan="" rowspan="2">Tournament</th>
            <th width="8%" class="header align-C" colspan="" rowspan="2" >Sky Avail</th>
            <th width="5%" class="align-C" colspan="" rowspan="2" >MO</th>
            <th width="5%" class="align-C" colspan="" rowspan="2" >TM</th>
            <!-- <th width="5%" class="align-C"colspan="" rowspan="2"  >TO</th> -->
            <th width="5%" class="align-C" colspan="" rowspan="2" >U/O</th>
            <th width="20%" class="header align-C" colspan="" rowspan="2">
              Matches
            </th>
            <th width="8%"  class="align-C" rowspan="2">
              MatchOdds
            </th>
            <th width="8%" class="header align-C" colspan="" rowspan="2"  class="align-C">
              P-winner
            </th>
            <th width="8%" class="header align-C" colspan="" rowspan="2" >
              BM D
            </th>
            <th width="8%" class="header align-C" colspan="" rowspan="2" >
              BM S
            </th>
            <th width="8%"  rowspan="2" class="align-C">
              UnderOver
            </th>
            <th class="header align-C" colspan="4">Bets</th>
          </tr>

          <tr class="ng-scope">
            <td class="align-C">MatchDate</td>
            <td class="align-C">Status</td>
            <td class="align-C">BFstatus</td>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let event of eventsLists">
            <tr>
              <td width="2%" class="align-C">
                <input type="checkbox" [(ngModel)]="event.activeStatus" />
              </td>
              <!-- <td width="2%" class="ng-scope" class="align-C"></td> -->
              <td class="align-C">{{ event.sportsName | titlecase }}</td>
              <!-- <td width="2%" class="align-C"></td> -->
              <!-- <td width="2%" class="align-C"></td> -->
              <!-- <td class="ng-scope" width="2%" class="align-C"></td> -->
              <td class="align-C">{{ event.competitionName }}</td>
              <!-- <td width="2%" class="align-C"></td>
                      <td width="2%" class="align-C"></td>
                      <td width="2%" class="align-C" class="ng-scope"></td> -->
              <td class="align-C" >
                <span *ngIf="event.isPremium==true">
                  <i class="fa fa-circle" aria-hidden="true" style="color: green;"></i>
                </span>
              </td>
              <td class="align-C" >
                <input type="checkbox" [(ngModel)]="event.matchOddsInplay1"
                  (change)="toggleActivateMO1($event, event.eventId)" />
              </td>
              <td class="align-C" >
                <input
                  *ngIf="event.sportsName.toLowerCase().includes('cricket')  || event.sportsName.toLowerCase().includes('kabaddi')"
                  type="checkbox" [(ngModel)]="event.tiedMatchInplay1
                      " (change)="toggleActivateTM1($event, event.eventId)" />
              </td>
              <!-- <td class="align-C" >
                      <input
                      *ngIf="event.sportsName.toLowerCase().includes('cricket')  || event.sportsName.toLowerCase().includes('kabaddi')"
                      type="checkbox" [(ngModel)]="event.winTheTossInplay1
                    " (change)="toggleActivateTossM1($event, event.eventId)" />
          
                      </td> -->
              <td  width="8%" class="align-C">
                <input *ngIf="event.sportsName.toLowerCase().includes('soccer')" type="checkbox" [(ngModel)]="event.overUnderInplay1
                        " (change)="toggleActivateOU1($event, event.eventId)" />
              </td>
              <td class="align-C">
                <a class="expand-close" (click)="toggleExpand($event, 'sub_' + event.eventId)"> + </a>
                {{ event.eventName }}
              </td>
              <td  width="8%" class="align-C">
                <input type="checkbox" [(ngModel)]="event.matchOddsInplay"
                  (change)="toggleActivateMO($event, event.eventId)" />
              </td>
              <td  width="8%" class="align-C">
                <input
                  *ngIf="event.premiumMain==1 && (event.sportsName.toLowerCase().includes('cricket')  || event.sportsName.toLowerCase().includes('soccer') || event.sportsName.toLowerCase().includes('tennis'))"
                  type="checkbox" [(ngModel)]="event.premiumMain"
                  (change)="toggleActivatewinner($event, event.eventId,0)" />
                <input
                  *ngIf="event.premiumMain==0 && (event.sportsName.toLowerCase().includes('cricket')  || event.sportsName.toLowerCase().includes('soccer') || event.sportsName.toLowerCase().includes('tennis'))"
                  type="checkbox" [(ngModel)]="event.premiumMain"
                  (change)="toggleActivatewinner($event, event.eventId,1)" />
              </td>
              <td  width="8%" class="align-C">
                <input
                  *ngIf="event.sportsName.toLowerCase().includes('cricket') || event.sportsName.toLowerCase().includes('soccer')"
                  type="checkbox" [(ngModel)]="event.bookmakerInplay_source0"
                  (change)="toggleBM($event, event.eventId,0)" />
              </td>
              <td  width="8%" class="align-C">
                <input
                  *ngIf="event.sportsName.toLowerCase().includes('cricket') || event.sportsName.toLowerCase().includes('soccer')"
                  type="checkbox" [(ngModel)]="event.bookmakerInplay_source1"
                  (change)="toggleBM($event, event.eventId,1)" />
              </td>
              <td  width="8%" class="align-C">
                <input *ngIf="event.sportsName.toLowerCase().includes('soccer')" type="checkbox" [(ngModel)]="event.overUnderInplay
                        " (change)="toggleActivateOU($event, event.eventId)" />
              </td>
              <!-- <td  width="8%" class="align-C">
                        <input
                          *ngIf="event.sportsName.toLowerCase().includes('cricket') || event.sportsName.toLowerCase().includes('soccer') || event.sportsName.toLowerCase().includes('kabaddi')"
                          type="checkbox" [(ngModel)]="event.bookmakerInplay_source1" (change)="toggleBM($event, event.eventId,1)" />
                      </td> -->
              <!-- <td width="2%" class="align-C"></td> -->
              <td width="10%" class="align-C">
                {{ event.time | date: "yyyy-MM-dd HH:mm:ss":timeFormat }}
              </td>
              <td title="Status" class="align-C">
                <label class="xyz">
                  <input type="checkbox" class="check-btn" [(ngModel)]="event.status" (change)="
                              activateEvent($event.target.checked, event.eventId)
                            " /><span class="slider round"></span></label>
              </td>

              <td class="align-C">open</td>
            </tr>
            <tr id="sub_{{ event.eventId }}" class="expand first import" style="display: none">
              <td colspan="1"></td>
              <td colspan="13">
                <ng-container *ngFor="let market of event.markets; index as i">
                  <table>
                    <tbody>
                      <tr>
                        <td class="align-C">
                          <input type="checkbox" [ngModel]="event.activeStatus"
                            (change)="activateGame($event, market.gameId)" />
                        </td>
                        <td width="100%" class="align-L">
                          <a *ngIf="market.runners && market.runners.length" class="expand-close" (click)="
                                      toggleMarkets(
                                        $event,
                                        'market_' + event.eventId + '_' + i
                                      )
                                    "> + </a>
                          <span>{{ market.marketName }}</span>
                        </td>
                      </tr>
                    </tbody>
                    <!-- <span class="spacer"></span> -->
                    <!-- <button class="btn">R</button> -->
                  </table>
                  <div *ngIf="market.runners && market.runners.length" id="market_{{ event.eventId }}_{{ i }}"
                    class="markets" style="display: none">
                    <div *ngFor="let runner of market.runners" class="market sub_market">
                      {{ runner.selName }}
                      <span class="spacer"></span>
                      <button class="btn">
                        <i class="fa fa-plus-circle" aria-hidden="true"></i>
                      </button>
                    </div>
                  </div>
                </ng-container>
              </td>
            </tr>
          </ng-container>
          <tr *ngIf="eventsLists?.length == 0">
            <td class="align-C" colspan="16">No Data Found</td>
          </tr>


        </tbody>
      </table>

      <table class="table table-striped table-bordered table-sm row-border hover" *ngIf="selectedTabIndex === 1"
        datatable [dtOptions]="dtOptions1" [dtTrigger]="dtTrigger1">
        <thead>
          <tr class="heading">
            <th width="2%" class="align-C">
              <input type="checkbox" name="select_all" [(ngModel)]="selectAll"
                (change)="toggleSelectAll($event.target.checked, 1)" id="select_all" />
            </th>
            <th id="market_info" class="align-C" width="23%">Market Info </th>
            <th width="5%" class="align-C">Status</th>
            <th *ngIf="showActions" width="6%" class="align-C">Inplay</th>
            <th width="5%" class="align-C">TV</th>
            <th width="5%" class="align-C" >Sky Avail</th>
            <th width="5%" class="align-C" colspan="" rowspan="2" >MO</th>
            <th width="5%" class="align-C" colspan="" rowspan="2" >TM</th>
            <!-- <th width="5%" class="align-C"colspan="" rowspan="2"  >TO</th> -->
            <th width="5%" class="align-C" colspan="" rowspan="2" >U/O</th>
            <th width="5%" class="align-C">Time</th>
            <th width="8%" class="align-C">UserOnline</th>
            <th width="8%" class="align-C">No off bets</th>
            <!-- <th *ngIf="showActions" width="8%" class="align-C">Session</th> -->
            <th *ngIf="showActions" width="8%" class="align-C">Action</th>
            <!-- <th *ngIf="showActions" width="6%" class="align-C">Reverse</th> -->
            <!-- <th width="8%"  class="align-C">Adv. fancy</th> -->
            <th width="8%"  class="align-C">
              Fancy D
            </th>
            <th width="8%"  class="align-C">
              Fancy S
            </th>
            <th width="8%"  class="align-C">
              Premium
            </th>
            <th width="8%"  class="align-C">
              MatchOdds
            </th>
            <th width="8%"  class="align-C">
              P-winner
            </th>
            <th width="8%"  class="align-C">
              Tiedmarket
            </th>
            <th width="8%"  class="align-C">
              Tossmarket
            </th>
            <!-- <th width="8%"  class="align-C">
                           Draw
                        </th> -->
            <th width="8%"  class="align-C"> BM D</th>
            <th width="8%"  class="align-C"> BM S</th>
            <th width="8%"  class="align-C">
              UnderOver
            </th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let event of activeGames">
            <tr id="event_{{ event.eventId }}" *ngIf="event.isInPlay === 1 && (event.status!=0)">
              <!-- <td class="align-C">
                      <a
                        (click)="toggleEventStatus(event)"
                        *ngIf="event.status === 1; else suspendTemp"
                      >
                        <i class="fa fa-play-circle fa-2x"></i>
                      </a>
                      <ng-template #suspendTemp>
                        <a (click)="toggleEventStatus(event)">
                          <i class="fa fa-pause-circle fa-2x loss-text"></i>
                        </a>
                      </ng-template>
                    </td> -->
              <td class="align-C">
                <input type="checkbox" name="select" [(ngModel)]="event.activeStatus" id="select" />
              </td>
              <td class="align-L">
                <a class="expand-close" (click)="toggleExpand($event, 'sub_' + event.eventId)"> + </a>
                {{ event.eventName }} <b *ngIf="event.competitionName"> - [ {{event.competitionName}} ]</b>
              </td>
              <td id="status_{{ event.eventId }}" class="align-C">
                <label class="xyz">
                  <input type="checkbox" class="check-btn" [(ngModel)]="event.status" (change)="
                            toggleEventStatus($event.target.checked, event)
                          " /><span class="slider round"></span></label>
              </td>
              <td *ngIf="showActions" id="isinplay_{{ event.eventId }}" class="align-C">
                <input type="checkbox" checked="checked" (change)="deactivateEvent(false, event.eventId)" id="bet" />
              </td>
              <td id="tv_{{ event.eventId }}" class="align-C">
                <span class="" [ngClass]="{'red':!event.isStream,'green':event.isStream}">
                  <i *ngIf="event.isStream === true; else noTvTemp" class="fa fa-television"></i>
                  <ng-template #noTvTemp>-</ng-template>
                </span>
              </td>
              <td class="align-C" >
                <span *ngIf="event.isPremium==true">
                  <i class="fa fa-circle" aria-hidden="true" style="color: green;"></i>
                </span>
              </td>
              <td class="align-C" >
                <input type="checkbox" [(ngModel)]="event.matchOddsInplay1"
                  (change)="toggleActivateMO1($event, event.eventId)" />
              </td>
              <td class="align-C" >
                <input
                  *ngIf="event.sportsName.toLowerCase().includes('cricket')  || event.sportsName.toLowerCase().includes('kabaddi')"
                  type="checkbox" [(ngModel)]="event.tiedMatchInplay1
                      " (change)="toggleActivateTM1($event, event.eventId)" />
              </td>

              <!-- <td class="align-C" >
                      <input
                      *ngIf="event.sportsName.toLowerCase().includes('cricket')  || event.sportsName.toLowerCase().includes('kabaddi')"
                      type="checkbox" [(ngModel)]="event.winTheTossInplay1" (change)="toggleActivateTossM1($event, event.eventId)" />
                      </td> -->

              <td  width="8%" class="align-C">
                <input *ngIf="event.sportsName.toLowerCase().includes('soccer')" type="checkbox"
                  [(ngModel)]="event.overUnderInplay1" (change)="toggleActivateOU1($event, event.eventId)" />
              </td>

              <td id="time_{{ event.eventId }}" class="align-C">
                <span class="red">{{ event.time | date: "yyyy-MM-dd HH:mm:ss":timeFormat }}
                </span>
              </td>
              <td id="user_online_{{ event.eventId }}" class="align-C">
                {{ event.usersOnline }}
              </td>
              <td id="no_bet_{{ event.eventId }}" class="align-C">
                {{ event.noOfBets }}
              </td>
              <!-- <td *ngIf="showActions" id="session_{{ event.eventId }}" class="align-C">
                      A<input type="radio" [(ngModel)]="event.session" name="sesssion_{{ event.eventId }}"
                        id="session__{{ event.eventId }}" value="A" title="Auto" />
                      M<input type="radio" [(ngModel)]="event.session" name="session_{{ event.eventId }}"
                        id="session__{{ event.eventId }}" value="M" title="Manual" />
                    </td> -->
              <td *ngIf="showActions" id="action_{{ event.eventId }}" class="align-C">
                <button title="Bet Suspend" class="btn btn-info" [class.bet-suspend]="event.betLock == 1" (click)="
                          toggleSuspend(event.betLock, event.sportId, event.eventId)
                        " [disabled]="isSuspendInTransit">
                  BS
                </button>
              </td>
              <!-- <td *ngIf="showActions" id="reverse_{{ event.eventId }}" class="align-C">
                      <button title="Reverse" class="align-C btn">Reverse</button>
                    </td> -->
              <!-- <td  width="8%" class="align-C">
                      <input *ngIf="event.sportsName.toLowerCase().includes('cricket') || event.sportsName.toLowerCase().includes('kabaddi')" type="checkbox"
                      [(ngModel)]="event.fancyInplay"
                      (change)="toggleActivateFancy($event, event.eventId)"
                      >
                    </td> -->
              <td  width="8%" class="align-C">
                <input
                  *ngIf="event.sportsName.toLowerCase().includes('cricket')  || event.sportsName.toLowerCase().includes('kabaddi')"
                  type="checkbox" [(ngModel)]="event.fancyInplay_source0"
                  (change)="toggleActivateFancy($event, event.eventId,0)" />
              </td>
              <td  width="8%" class="align-C">
                <input
                  *ngIf="event.sportsName.toLowerCase().includes('cricket')  || event.sportsName.toLowerCase().includes('kabaddi')"
                  type="checkbox" [(ngModel)]="event.fancyInplay_source1"
                  (change)="toggleActivateFancy($event, event.eventId,1)" />
              </td>
              <td  width="8%" class="align-C">
                <input type="checkbox" [(ngModel)]="event.premiumInplay"
                  (change)="toggleActivatepremium($event, event.eventId)" />
              </td>
              <td  width="8%" class="align-C">
                <input type="checkbox" [(ngModel)]="event.matchOddsInplay"
                  (change)="toggleActivateMO($event, event.eventId)" />
              </td>
              <td  width="8%" class="align-C">
                <input
                  *ngIf="event.premiumMain==1 && (event.sportsName.toLowerCase().includes('cricket')  || event.sportsName.toLowerCase().includes('soccer') || event.sportsName.toLowerCase().includes('tennis'))"
                  type="checkbox" [(ngModel)]="event.premiumMain"
                  (change)="toggleActivatewinner($event, event.eventId,0)" />
                <input
                  *ngIf="event.premiumMain==0 && (event.sportsName.toLowerCase().includes('cricket')  || event.sportsName.toLowerCase().includes('soccer') || event.sportsName.toLowerCase().includes('tennis'))"
                  type="checkbox" [(ngModel)]="event.premiumMain"
                  (change)="toggleActivatewinner($event, event.eventId,1)" />
              </td>
              <td  width="8%" class="align-C">
                <input
                  *ngIf="event.sportsName.toLowerCase().includes('cricket')  || event.sportsName.toLowerCase().includes('kabaddi')"
                  type="checkbox" [(ngModel)]="event.tiedMatchInplay
                        " (change)="toggleActivateTM($event, event.eventId)" />
              </td>
              <td  width="8%" class="align-C">
                <input
                  *ngIf="event.sportsName.toLowerCase().includes('cricket')  || event.sportsName.toLowerCase().includes('kabaddi')"
                  type="checkbox" [(ngModel)]="event.winTheTossInplay
                        " (change)="toggleActivateTossM($event, event.eventId)" />
              </td>
              <!-- <td  width="8%" class="align-C">
                      <input *ngIf="event.sportsName.toLowerCase().includes('cricket')" type="checkbox"
                        [(ngModel)]="event.winTheTossInplay" (change)="toggleActivateDraw($event, event.eventId)" />
                    </td> -->
              <td  width="8%" class="align-C">
                <input
                  *ngIf="event.sportsName.toLowerCase().includes('cricket') || event.sportsName.toLowerCase().includes('soccer')|| event.sportsName.toLowerCase().includes('kabaddi')"
                  type="checkbox" [(ngModel)]="event.bookmakerInplay_source0"
                  (change)="toggleBM($event, event.eventId,0)" />
              </td>
              <td  width="8%" class="align-C">
                <input
                  *ngIf="event.sportsName.toLowerCase().includes('cricket') || event.sportsName.toLowerCase().includes('soccer') || event.sportsName.toLowerCase().includes('kabaddi')"
                  type="checkbox" [(ngModel)]="event.bookmakerInplay_source1"
                  (change)="toggleBM($event, event.eventId,1)" />
              </td>
              <!-- <td>
                      <button class="btn" (click)="closeEvent(event.sportId ,event.eventId)">
                        C
                      </button>
                    </td> -->
              <td  width="8%" class="align-C">
                <input *ngIf="event.sportsName.toLowerCase().includes('soccer')" type="checkbox" [(ngModel)]="event.overUnderInplay
                        " (change)="toggleActivateOU($event, event.eventId)" />
              </td>
            </tr>
            <tr id="sub_{{ event.eventId }}" class="expand first" style="display: none">
              <td></td>
              <td colspan="14">
                <ng-container *ngFor="let market of event.markets; index as i">
                  <div class="market">
                    <a *ngIf="market.runners && market.runners.length" class="expand-close" (click)="
                              toggleMarkets($event, 'market_' + event.eventId + '_' + i)
                            "> + </a>
                    {{ market.marketName }}
                    <span class="spacer"></span>
                    <ng-container >

                      <button title="Back All" class="btn back" [ngClass]="{'lock':market.lockBackALL}"
                        (click)="lockRunner(market,'all','back')">Back All</button>
                      <button title="Lay All" class="btn lay" [ngClass]="{'lock':market.lockLayALL}"
                        (click)="lockRunner(market,'all','lay')">Lay All</button>

                    </ng-container>
                    <!-- <button title="Reverse" class="btn">R</button> -->
                    <label class="xyz">
                      <input type="checkbox" class="check-btn" [(ngModel)]="market.status" (change)="
                            toggleMarketStatus($event.target.checked, market.gameId)
                              " /><span class="slider round"></span></label>
                  </div>
                  <div *ngIf="market.runners && market.runners.length" id="market_{{ event.eventId }}_{{ i }}"
                    class="markets" style="display: none">
                    <div *ngFor="let runner of market.runners" class="market sub_market">
                      {{ runner.selName }}
                      <span class="spacer"></span>
                      <ng-container >

                        <button title="BackLay All" class="btn both"
                          [ngClass]="{'lock':runner.lockBackBets && runner.lockLayBets}"
                          (click)="lockRunner(market,runner.selName,'backlay')">Both</button>
                        <button title="Back" class="btn back" [ngClass]="{'lock':runner.lockBackBets}"
                          (click)="lockRunner(market,runner.selName,'back')">Back</button>
                        <button title="Lay" class="btn lay" [ngClass]="{'lock':runner.lockLayBets}"
                          (click)="lockRunner(market,runner.selName,'lay')">Lay</button>
                      </ng-container>
                      <button class="btn">
                        <i class="fa fa-plus-circle" aria-hidden="true"></i>
                      </button>
                    </div>
                  </div>
                </ng-container>
              </td>
            </tr>
          </ng-container>

          <tr *ngIf="activeGames?.length == 0">
            <td class="align-C" colspan="23">No Data Found</td>
          </tr>


        </tbody>
      </table>

      <table class="table table-striped table-bordered table-sm row-border hover" *ngIf="selectedTabIndex === 2"
        datatable [dtOptions]="dtOptions2" [dtTrigger]="dtTrigger2">
        <thead>
          <tr id="head">
            <!-- <th width="3%"></th> -->
            <th width="2%" class="align-C">
              <input type="checkbox" [(ngModel)]="selectAll" (change)="toggleSelectAll($event.target.checked, 2)"
                name="select_all" id="select_all" />
            </th>
            <th id="market_info" class="align-C"  width="23%">Market Info</th>
            <th width="5%" class="align-C">Status</th>
            <th *ngIf="showActions" width="8%" class="align-C">IsInplay</th>
            <th width="5%" class="align-C" >Sky Avail</th>
            <th width="5%" class="align-C" colspan="" rowspan="2" >MO</th>
            <th width="5%" class="align-C" colspan="" rowspan="2" >TM</th>
            <!-- <th width="5%" class="align-C"colspan="" rowspan="2"  >TO</th> -->
            <th width="5%" class="align-C" colspan="" rowspan="2" >U/O</th>
            <th width="5%" class="align-C">Time</th>
            <th  width="8%" class="align-C">
              Activate fancy D
            </th>
            <th  width="8%" class="align-C">
              Activate fancy S
            </th>
            <th  width="10%" class="align-C">
              Activate Premium
            </th>
            <th  width="10%" class="align-C">
              Activate MatchOdds
            </th>
            <th width="8%"  class="align-C">
              P-winner
            </th>
            <th  width="10%" class="align-C">
              Activate Tiedmarket
            </th>
            <th  width="10%" class="align-C">
              Activate Tossmarket
            </th>
            <!-- <th  width="8%" class="align-C">
            Activate Draw
          </th> -->
            <th  width="8%" class="align-C">Activate BM D</th>
            <th  width="8%" class="align-C">Activate BM S</th>
            <th  width="10%" class="align-C">
              Activate UnderOver
            </th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let event of activeGames">
            <tr id="event_{{ event.eventId }}" *ngIf="event.isInPlay === 0 &&  event.status==1">
              <!-- <td class="align-C">
              <a (click)="toggleEventStatus(event)" *ngIf="event.status === 1; else suspendTemp">
                <i class="fa fa-play-circle fa-2x"></i>
              </a>
              <ng-template #suspendTemp>
                <a (click)="toggleEventStatus(event)">
                  <i class="fa fa-pause-circle fa-2x loss-text"></i>
                </a>
              </ng-template>
            </td> -->
              <td class="align-C">
                <input type="checkbox" [(ngModel)]="event.activeStatus" name="select" id="select" />
              </td>
              <td class="align-L">
                <a class="expand-close" (click)="toggleExpand($event, 'sub_' + event.eventId)"> + </a>
                {{ event.eventName }} <b *ngIf="event.competitionName"> - [ {{event.competitionName}} ]</b>
              </td>
              <td id="status_{{ event.eventId }}" class="align-C">
                <label class="xyz">
                  <input type="checkbox" class="check-btn" [(ngModel)]="event.status" (change)="
                    toggleEventStatus($event.target.checked, event)
                  " /><span class="slider round"></span></label>
              </td>
              <td *ngIf="showActions" id="isinplay_{{ event.eventId }}" class="align-C">
                <input type="checkbox" (change)="toggleInplay(event.eventId)" id="bet" />
              </td>
              <td  class="align-C">
                <span *ngIf="event.isPremium==true">
                  <i class="fa fa-circle" aria-hidden="true" style="color: green;"></i>
                </span>
              </td>
              <td class="align-C" >
                <input type="checkbox" [(ngModel)]="event.matchOddsInplay1"
                  (change)="toggleActivateMO1($event, event.eventId)" />
              </td>
              <td class="align-C" >
                <input
                  *ngIf="event.sportsName.toLowerCase().includes('cricket')  || event.sportsName.toLowerCase().includes('kabaddi')"
                  type="checkbox" [(ngModel)]="event.tiedMatchInplay1
                " (change)="toggleActivateTM1($event, event.eventId)" />
              </td>
              <!-- <td class="align-C" >
                <input
                *ngIf="event.sportsName.toLowerCase().includes('cricket')  || event.sportsName.toLowerCase().includes('kabaddi')"
                type="checkbox" [(ngModel)]="event.winTheTossInplay1
              " (change)="toggleActivateTossM1($event, event.eventId)" />
  
                </td> -->
              <td  width="8%" class="align-C">
                <input *ngIf="event.sportsName.toLowerCase().includes('soccer')" type="checkbox" [(ngModel)]="event.overUnderInplay1
                  " (change)="toggleActivateOU1($event, event.eventId)" />
              </td>
              <td id="time_{{ event.eventId }}" class="align-C">
                <span class="red">{{ event.time | date: "yyyy-MM-dd HH:mm:ss":timeFormat }}
                </span>
              </td>
              <td  id="" width="8%" class="align-C">
                <input
                  *ngIf="event.sportsName.toLowerCase().includes('cricket')  || event.sportsName.toLowerCase().includes('kabaddi')"
                  type="checkbox" [(ngModel)]="event.fancyInplay_source0"
                  (change)="toggleActivateFancy($event, event.eventId,0)" />
              </td>
              <td  id="" width="8%" class="align-C">
                <input
                  *ngIf="event.sportsName.toLowerCase().includes('cricket')  || event.sportsName.toLowerCase().includes('kabaddi')"
                  type="checkbox" [(ngModel)]="event.fancyInplay_source1"
                  (change)="toggleActivateFancy($event, event.eventId,1)" />
              </td>
              <td  id="" width="8%" class="align-C">
                <input type="checkbox" [(ngModel)]="event.premiumInplay"
                  (change)="toggleActivatepremium($event, event.eventId)" />
              </td>
              <td  id="" width="8%" class="align-C">
                <input type="checkbox" [(ngModel)]="event.matchOddsInplay
                " (change)="toggleActivateMO($event, event.eventId)" />
              </td>
              <td  width="8%" class="align-C">
                <input
                  *ngIf="event.premiumMain==1 && (event.sportsName.toLowerCase().includes('cricket')  || event.sportsName.toLowerCase().includes('soccer') || event.sportsName.toLowerCase().includes('tennis'))"
                  type="checkbox" [(ngModel)]="event.premiumMain"
                  (change)="toggleActivatewinner($event, event.eventId,0)" />
                <input
                  *ngIf="event.premiumMain==0 && (event.sportsName.toLowerCase().includes('cricket')  || event.sportsName.toLowerCase().includes('soccer') || event.sportsName.toLowerCase().includes('tennis'))"
                  type="checkbox" [(ngModel)]="event.premiumMain"
                  (change)="toggleActivatewinner($event, event.eventId,1)" />
              </td>
              <td  id="" width="8%" class="align-C">
                <input
                  *ngIf="event.sportsName.toLowerCase().includes('cricket')  || event.sportsName.toLowerCase().includes('kabaddi')"
                  type="checkbox" [(ngModel)]="event.tiedMatchInplay
                " (change)="toggleActivateTM($event, event.eventId)" />
              </td>
              <td  id="" width="8%" class="align-C">
                <input
                  *ngIf="event.sportsName.toLowerCase().includes('cricket')  || event.sportsName.toLowerCase().includes('kabaddi')"
                  type="checkbox" [(ngModel)]="event.winTheTossInplay
                " (change)="toggleActivateTossM($event, event.eventId)" />
              </td>
              <!-- <td  width="8%" class="align-C">
              <input *ngIf="event.sportsName.toLowerCase().includes('cricket')" type="checkbox"
                [(ngModel)]="event.winTheTossInplay" (change)="toggleActivateDraw($event, event.eventId)" />
            </td> -->
              <td  id="" width="8%" class="align-C">
                <input
                  *ngIf="event.sportsName.toLowerCase().includes('cricket') || event.sportsName.toLowerCase().includes('kabaddi') || event.sportsName.toLowerCase().includes('soccer')"
                  type="checkbox" [(ngModel)]="event.bookmakerInplay_source0"
                  (change)="toggleActivateBM($event, event.eventId,0)" />
              </td>
              <td  id="" width="8%" class="align-C">
                <input
                  *ngIf="event.sportsName.toLowerCase().includes('cricket') || event.sportsName.toLowerCase().includes('kabaddi') || event.sportsName.toLowerCase().includes('soccer')"
                  type="checkbox" [(ngModel)]="event.bookmakerInplay_source1"
                  (change)="toggleActivateBM($event, event.eventId,1)" />
              </td>
              <td  id="" width="8%" class="align-C">
                <input *ngIf="event.sportsName.toLowerCase().includes('soccer')" type="checkbox" [(ngModel)]="event.overUnderInplay
                " (change)="toggleActivateOU($event, event.eventId)" />
              </td>
            </tr>
            <tr id="sub_{{ event.eventId }}" class="expand first" style="display: none">
              <td colspan="1"></td>
              <td colspan="14">
                <ng-container *ngFor="let market of event.markets; index as i">
                  <div class="market">
                    <a *ngIf="market.runners && market.runners.length" class="expand-close" (click)="
                      toggleMarkets($event, 'market_' + event.eventId + '_' + i)
                    "> + </a>
                    {{ market.marketName }}
                    <span class="spacer"></span>
                    <!-- <button class="btn">R</button> -->
                    <ng-container >
                      <button title="Back All" class="btn back" [ngClass]="{'lock':market.lockBackALL}"
                        (click)="lockRunner(market,'all','back')">Back All</button>
                      <button title="Lay All" class="btn lay" [ngClass]="{'lock':market.lockLayALL}"
                        (click)="lockRunner(market,'all','lay')">Lay All</button>
                    </ng-container>
                    <label class="xyz">
                      <input type="checkbox" class="check-btn" [(ngModel)]="market.status" (change)="
                    toggleMarketStatus($event.target.checked, market.gameId)
                      " /><span class="slider round"></span></label>
                  </div>
                  <div *ngIf="market.runners && market.runners.length" id="market_{{ event.eventId }}_{{ i }}"
                    class="markets" style="display: none">
                    <div *ngFor="let runner of market.runners" class="market sub_market">
                      {{ runner.selName }}
                      <span class="spacer"></span>
                      <ng-container >

                        <button title="BackLay All" class="btn both"
                          [ngClass]="{'lock':runner.lockBackBets && runner.lockLayBets}"
                          (click)="lockRunner(market,runner.selName,'backlay')">Both</button>
                        <button title="Back" class="btn back" [ngClass]="{'lock':runner.lockBackBets}"
                          (click)="lockRunner(market,runner.selName,'back')">Back</button>
                        <button title="Lay" class="btn lay" [ngClass]="{'lock':runner.lockLayBets}"
                          (click)="lockRunner(market,runner.selName,'lay')">Lay</button>
                      </ng-container>
                      <button class="btn">
                        <i class="fa fa-plus-circle" aria-hidden="true"></i>
                      </button>
                    </div>
                  </div>
                </ng-container>
              </td>
            </tr>
          </ng-container>

          <tr *ngIf="activeGames?.length == 0">
            <td class="align-C" colspan="19">No Data Found</td>
          </tr>
        </tbody>
      </table>




    </div>

  </mat-card-content>
</mat-card>

<div id="isliquidityModalOpen" class="modal fade" role="dialog" aria-labelledby="isliquidityModalOpen" aria-hidden="true">
  <div class="modal-dialog modal-lg">
      <div class="modal-content">
          <div class="modal-header">
              <span>Set Liquidity</span>
              <button type="button" class="close" data-dismiss="modal">
                  <div class="close_new"><i class="fa fa-times-circle"></i> </div>
              </button>
          </div>

          <div class="modal-body">
              <div class="popup_form_row" *ngIf="liquiditydata">
                <div class="form-group margrp">
                  <label for="soccerLiquidity">Soccer Liquidity</label>
                  <input type="number" id="soccerLiquidity" [(ngModel)]="liquiditydata.soccerLiquidity" class="form-control">
                </div>
                <div class="form-group margrp">
                  <label for="tennisLiquidity">Tennis Liquidity</label>
                  <input type="number" id="tennisLiquidity" [(ngModel)]="liquiditydata.tennisLiquidity" class="form-control">
                </div>
              </div>
              <div class="popup_col_12 button-row">
                <button mat-raised-button color="primary" type="button"  (click)="setLiquidity()">Yes</button>
                <button mat-raised-button color="primary" data-dismiss="modal" type="button" (click)="cancelliq()">No</button>
            </div>
          </div>
      </div>
  </div>
</div>