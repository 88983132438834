import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { AwcserviceService } from './awcservice.service';
import { NotificationService } from '../Services/notification.service';
import { DataTableDirective } from 'angular-datatables';

@Component({
  selector: 'app-awcwhitelist',
  templateUrl: './awcwhitelist.component.html',
  styleUrls: ['./awcwhitelist.component.scss']
})
export class AwcwhitelistComponent implements OnInit,OnDestroy,AfterViewInit {
  @ViewChild(DataTableDirective, { static: true }) dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  AwcForm: FormGroup;
  todate:string;
  date:string;
  awcdata:any;
  agents:any;
  awclists:any;
  agent:any;
  cert:any;
  domainname:any;
  apiurl:any ;
  selecteddomain:any;
  agentsMap1 = [
    {
      "id":"1",
      "agent":"vrnl1",
      "cert":"74I6ROym6snOFcN08ZY",
      "apiurl":"https://gciap.usplaynet.com/",
      "owner":"Tuhin"
    },
    {
      "id":"2",
      "agent":"vrnl3",
      "cert":"wAmdwDi6Kap3B3DsiA6",
      "apiurl":"https://gciap.usplaynet.com/",
      "owner":"Ariyan"
    },
    {
      "id":"3",
      "agent":"vrnl4",
      "cert":"RGpN5kROHgkIt9loS5t",
      "apiurl":"https://gciap.usplaynet.com/",
      "owner":"Hafees"
    },
    {
      "id":"4",
      "agent":"vrnl5",
      "cert":"rUe9T58lwVawBUNSTZr",
      "apiurl":"https://gciap.usplaynet.com/",
      "owner":"Vrnl sites"
    },
    {
      "id":"5",
      "agent":"vrnl6",
      "cert":"exKKqTxPe4i0YweMBKe",
      "apiurl":"https://gciap.usplaynet.com/",
      "owner":"vrnl sites"
    },
    {
      "id":"6",
      "agent":"vrnljohn",
      "cert":"IvRkKSOA1l2SBkU9wUl",
      "apiurl":"https://gciap.usplaynet.com/",
      "owner":"vrnl sites"
    },
    {
      "id":"7",
      "agent":"vrnljohn",
      "cert":"IvRkKSOA1l2SBkU9wUl",
      "apiurl":"https://gciap.usplaynet.com/",
      "owner":"Rimon PKR"
    },
    {
      "id":"7",
      "agent":"royelgaming",
      "cert":"6CM317STJd0eq0bpy9n",
      "apiurl":"https://api.onlinegames22.com/",
      "owner":"Rimon MYR"
    },
  ]
  agentsMap = [
    {
      "id":"1",
      "agent":"tuhinagt",
      "cert":"dcxStXSAfm4tvH2BGYD",
      "apiurl":"https://fsaap.velkigames365.cc/",
      "owner":"Tuhin"
    },
    {
      "id":"2",
      "agent":"vrnlag1",
      "cert":"lBQLrDEQVRXIDoJIJev",
      "apiurl":"https://fsaap.velkigames365.cc/",
      "owner":"inhouse"
    },
    {
      "id":"3",
      "agent":"vrnlag2",
      "cert":"aRMLgzGwKdrbBVeBWEK",
      "apiurl":"https://fsaap.velkigames365.cc/",
      "owner":"inhouse"
    },
    {
      "id":"4",
      "agent":"vrnlag3",
      "cert":"4BP393355uMdlF0I7Av",
      "apiurl":"https://fsaap.velkigames365.cc/",
      "owner":"Ariiyan sites"
    },
    {
      "id":"5",
      "agent":"vrnlag4",
      "cert":"fM0rkB7S5l3wjNpGG8F",
      "apiurl":"https://fsaap.velkigames365.cc/",
      "owner":"Hafees sites"
    },
    {
      "id":"6",
      "agent":"vrnlag5",
      "cert":"H6LxrdYWO6tNwPgyDal",
      "apiurl":"https://fsaap.velkigames365.cc/",
      "owner":"vrnl sites"
    },
    {
      "id":"7",
      "agent":"agvrnl",
      "cert":"ySJEv0ktZaZ6kB8BHZP",
      "apiurl":"https://fsaap.velkigames365.cc/",
      "owner":"Test min 5 stake"
    },
  ]
  agentsMap2 = [
    {
      "id":"1",
      "agent":"vrnl1",
      "cert":"74I6ROym6snOFcN08ZY",
      "apiurl":"https://gciap.usplaynet.com/",
      "owner":"Tuhin"
    },
    {
      "id":"1",
      "agent":"agvrnl1",
      "cert":"AWr0yaiQTnIyoCLuHsE",
      "apiurl":"https://api.onlinegames22.com/",
      "owner":"VRNL"
    },
    {
      "id":"2",
      "agent":"agvrnl2",
      "cert":"1Zn3u8tzdj2S81PjNxB",
      "apiurl":"https://api.onlinegames22.com/",
      "owner":"VRNL Premium"
    },
    {
      "id":"3",
      "agent":"agvrnl3",
      "cert":"m3fI0WnUiqWAw5YAagq",
      "apiurl":"https://api.onlinegames22.com/",
      "owner":"Ariyan"
    },
    {
      "id":"4",
      "agent":"agvrnl4",
      "cert":"QrHXJc3zo7UbocFsnSk",
      "apiurl":"https://api.onlinegames22.com/",
      "owner":"Hafees"
    },
    {
      "id":"5",
      "agent":"agvrnl5",
      "cert":"95X46cU4v3JiaCziRcv",
      "apiurl":"https://api.onlinegames22.com/",
      "owner":"VRNL spare"
    },
    {
      "id":"6",
      "agent":"agvrnl",
      "cert":"ySJEv0ktZaZ6kB8BHZP",
      "apiurl":"https://fsaap.velkigames365.cc/",
      "owner":"Test min 5 stake"
    },
  ]
  // betlimit for old agents
  // defaultBetlimit = {"SV388":{"LIVE":{"maxbet":5000,"minbet":1,"mindraw":1,"matchlimit":5000,"maxdraw":500}},"HORSEBOOK":{"LIVE":{"minbet":5,"maxbet":8000,"maxBetSumPerHorse":8000,"minorMinbet":5, "minorMaxbet":3000, "minorMaxBetSumPerHorse":3000}},"BG":{"LIVE":{"limitId":["H1"]}},"SEXYBCRT":{"LIVE":{"limitId":[140111]}},"VENUS":{"LIVE":{"limitId":[140111]}}};
  
  // betlimit for new agent min bet 5
  //defaultBetlimit = {"SV388":{"LIVE":{"maxbet":5000,"minbet":5,"mindraw":5,"matchlimit":5000,"maxdraw":500}},"HORSEBOOK":{"LIVE":{"minbet":5,"maxbet":8000,"maxBetSumPerHorse":8000,"minorMinbet":5, "minorMaxbet":3000, "minorMaxBetSumPerHorse":3000}},"BG":{"LIVE":{"limitId":["H1"]}},"HOTROAD":{"LIVE":{"limitId":[100006]}},"SEXYBCRT":{"LIVE":{"limitId":[830122]}},"VENUS":{"LIVE":{"limitId":[830110]}}};
  
  // betlimit for new agents min bet 1
    defaultBetlimit = {"SV388":{"LIVE":{"maxbet":5000,"minbet":4,"mindraw":4,"matchlimit":5000,"maxdraw":500}},"HORSEBOOK":{"LIVE":{"minbet":5,"maxbet":8000,"maxBetSumPerHorse":8000,"minorMinbet":5, "minorMaxbet":3000, "minorMaxBetSumPerHorse":3000}},"BG":{"LIVE":{"limitId":["H1"]}},"HOTROAD":{"LIVE":{"limitId":[100006]}},"SEXYBCRT":{"LIVE":{"limitId":[830110]}},"VENUS":{"LIVE":{"limitId":[830110]}}};
  
  constructor(private fb:FormBuilder,private awclist:AwcserviceService,private notifyService:NotificationService) { }

  ngOnInit(): void {
    this.date = moment().format("YYYY-MM-DD")
    var new_date = moment(this.date, "YYYY-MM-DD").add(365*2, 'days').toDate();
    this.todate = moment(new_date).format("YYYY-MM-DD")
    this.initUserForm();
    this.initDatatable();
    this.getAwcList();
  }

  getAwcList(){
    this.awclist.awclist().subscribe((res)=>{
      if(res){
        this.awclists =res
        this.rerender();
      }      
    })
  }

  initDatatable() {
    // console.log('table')
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      responsive: true,
      deferRender: true,
      order:[[0, 'desc']]
    };
  }


  initUserForm() {
    this.AwcForm = this.fb.group({
      domain: ['', Validators.required],
      selectmenu: ['', Validators.required]      
    });
  }

  Addawc(){
    if (this.AwcForm.valid) {
      this.awcdata= this.AwcForm.value;
      this.domainname = this.awcdata.domain.trim().toLowerCase();
      this.awclist.AddAwc(this.domainname,this.agent,this.cert,this.apiurl,this.defaultBetlimit).subscribe((result)=>{
        console.log("success",result);
        if (result =="ok") {        
          this.notifyService.success("Domain added successfuly");
          $("[data-dismiss=modal]").trigger("click");
          this.getAwcList();
        } else {
          this.notifyService.error(result);
        }
      })
   

    }
    
  }

  ngAfterViewInit() {
    this.dtTrigger.next();
  }

  ngOnDestroy() {
    this.dtTrigger.unsubscribe();
  }

  rerender() {
    if (this.dtElement && this.dtElement.dtInstance) {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        // Destroy the table first
        dtInstance.destroy();
        // Call the dtTrigger to rerender again
        this.dtTrigger.next();
      });
    }
  }

  changeAgent(event:any){
    this.agentsMap.forEach((agent)=>{
      if(agent.id==event.target.value){
        this.agent =agent.agent
        this.cert =agent.cert
        this.apiurl =agent.apiurl
      }
    })
  }

  setSelecteddomain(dname){
    this.selecteddomain = dname
    console.log("  this.selecteddomain",  this.selecteddomain);
    
  }
  deletedomain(){
    this.awclist.awcDelete(this.selecteddomain).subscribe(result => {
      if (result =="ok") {        
        this.notifyService.success("Domain deleted successfuly");
        $("[data-dismiss=modal]").trigger("click");
        this.getAwcList();
      } else {
        this.notifyService.error(result);
      }
    })

  }

}
