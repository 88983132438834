import { DatePipe } from '@angular/common';
import {  Component,  OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataTableDirective } from 'angular-datatables';
import { Subject, Subscription } from 'rxjs';
import { NotificationService } from '../Services/notification.service';
import { TokenService } from '../Services/token.service';
import { PremiumService } from './premium.service';
import { PremiumModel } from './premium_model';
@Component({
  selector: 'app-premium',
  templateUrl: './premium.component.html',
  styleUrls: ['./premium.component.scss']
})
export class PremiumComponent implements OnInit {

  @ViewChild(DataTableDirective, { static: true }) dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  AddFancyForm: FormGroup;
  fancyList: PremiumModel[];

  checknewfancy: Subscription;
  Allimportedfancies: any = [];
  options = [
  {
    status: 0,
    type:"Active"
  },
  {
    status: 1,
    type:"Settled"
  },
  {
    status: 2,
    type:"Canceled"
  }];
  sports = [
    {
      sportbfid: 4,
      name:"Cricket"
    },
    {
      sportbfid: 1,
      name:"Soccer"
    },
    {
      sportbfid: 2,
      name:"Tennis"
    }
  ];

  sportsAdd = [
    {
      sportbfid: 4,
      name:"Cricket"
    },
    {
      sportbfid: 1,
      name:"Soccer"
    },
    {
      sportbfid: 2,
      name:"Tennis"
    }
  ];
  selectedStatus: any = 0;
  selectedSport: any = 4;
  selectedSportToAdd:any="";
  loader = false;
  selectedevent:any="";
  selectedeventToAdd:any="";
  fancydata: any;
  AllfancyList: any;
  Oldimportedfancy: any=[];
  Settlesession: any;
  highlights: any;
  highlightsAdd: any;
  Search: any;
  selectfromdate;
  selecttodate;
  eventDate:any="";
  eventId:any="";
  
  constructor(private fb: FormBuilder,private datePipe: DatePipe, private allapi: PremiumService, private notifyService: NotificationService,private tokenService: TokenService) {
    $(document).on("focus", 'input[type="search"]', function () {
      var ele:any = $('input[type="search"]').val();
      var table = $("#fancytable").DataTable();
      table.search(ele, false, false, false).draw();
    });
  }
  

  ngOnInit(): void {
    // this.selectfromdate = new Date(new Date());
    // this.selectfromdate = new Date(new Date().setHours(0, 0, 0));
    
    this.initUserForm();
    this.getHighlights();
    this.initDatatable();    
  }

  initUserForm() {
    this.AddFancyForm = this.fb.group({
      sportName: ['', Validators.required],
      eventname: ['', Validators.required],
      marketname: ['', Validators.required],
    });
    this.eventDate="";
    this.eventId="";
  }

  initDatatable() {
    // console.log('table')
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 500,
      processing: true,
      responsive: true,
      deferRender: true,
      order:[[0, 'desc']]
    };
  }

  getFancyList(term?: string) {
    if(this.selectedevent==""){
      this.notifyService.error("Please select event");
      return false
    }
    this.loader = true;
    console.log(this.selectedevent);
    
    if(this.selectedSport!=null && this.selectedStatus!=null && this.selectedevent!=""){
      this.allapi.GetSportbookmarket(this.selectedSport,this.selectedStatus,this.selectedevent).subscribe(resp => {
        this.fancyList = resp.data;
        this.fancyList.forEach(element => {
          if(element.sbrunners!=null){
            element.sbrunners = element.sbrunners.toString().replace("/","");
            element.sbrunners = JSON.parse(element.sbrunners.toString().replace("/",""));
          }
          if(element.result==null){
            element.result= "";
          }
        });
        this.loader = false;
        this.rerender();
      })
    }
  }

  resetForm() {
      this.initUserForm();
  }

  getHighlights() {
    if(this.selectedSport!=null){
      this.allapi.GeteventsbySports(this.selectedSport).subscribe(resp => {
        this.highlights = resp.data;
      })
    }
  }

  getHighlightsToAdd(){
    if(this.selectedSportToAdd!=null && this.selectedSportToAdd!=""){
      this.allapi.GeteventsbySports(this.selectedSportToAdd).subscribe(resp => {
        this.highlightsAdd = resp.data;
      })
    }
  }


  addMarket() {
    var data = {
      "eventName": this.AddFancyForm.value.eventname.eventname,
      "eventDate": this.AddFancyForm.value.eventname.eventdate,
      "eventId": this.AddFancyForm.value.eventname.eventid,
      "sbmarketname": this.AddFancyForm.value.marketname,
      "sbrunners": null,
      "sbmarketid": null,
      "sportType": this.selectedSportToAdd,
    }
  //  console.log(data)
    this.allapi.AddSpBook(data).subscribe(data => {
      if (data.status =="Success") {
        this.notifyService.success(data.result);
      } else {
        this.notifyService.error(data.result);
      }
    })
  }

  SettleBook(fancy) {
   console.log(fancy);
    
    if (fancy.result == null || fancy.result == "") {
      this.notifyService.error("Please Select Result");
      return false;
    }
    var body = {
      "sbid":fancy.sbid,
      "status": 1,
      "result": fancy.result.selectionName,
      "settledby": this.tokenService.getUsername(),
      "reversed":null,
      "selectionId":fancy.result.selectionId
    }
  //  console.log(body);
    

    this.allapi.SettleSportBook(body).subscribe(data => {
      if (data.status =="Success") {
        this.getFancyList();
        $("[data-dismiss=modal]").trigger("click");
        this.notifyService.success(data.message);
      } else {
        this.notifyService.error(data.message);
      }
    })
  }

  fancyinfodata(data) {
    this.fancydata = data;
  //  console.log(this.fancydata);
  }

  cancelfancy(fancy) {
    var data = {
      "sbid":fancy.sbid,
      "status": 2,
      "result": -1,
      "settledby": this.tokenService.getUsername(),
      "reversed":null,
      "selectionId":null
    }
   
    this.allapi.SettleSportBook(data).subscribe(data => {
      if (data.status =="Success") {
        this.getFancyList();
        $("[data-dismiss=modal]").trigger("click");
        this.notifyService.success("Market Canceled Successfully...");
      } else {
        this.notifyService.error(data.message);
      }
    })
  }

  reversefancy(fancy) {
    var body = {
      "sbid":fancy.sbid,
      "status": 0,
      "result": null,
      "settledby": fancy.settledby,
      "reversed":this.tokenService.getUsername(),
      "selectionId":null
    }

    this.allapi.SettleSportBook(body).subscribe(data => {
      if (data.status =="Success") {
        this.getFancyList();
        $("[data-dismiss=modal]").trigger("click");
        this.notifyService.success("Market Reversed Successfully...");
      } else {
        this.notifyService.error(data.message);
      }
    })
  }


  onchangeinput(d) {
    if (d != null) {
      var event = [`${d.getFullYear()}`, `0${d.getMonth()+1}`.substr(-2), `0${d.getDate()}`.substr(-2)].join("-");
    } else {
      event = "";
    }
    // console.log(d, [`${d.getFullYear()}`, `0${d.getMonth()+1}`.substr(-2), `0${d.getDate()}`.substr(-2)].join("-"));
      var table = $("#fancytable").DataTable();
      table.search(event, false, false, false).draw();
  }

  trackfancyid(index,fancy)
  {
    return fancy.Id;
  }
  trckbyeventid(index,high)
  {
    return high.gameId;
  }
  ngAfterViewInit() {
    this.dtTrigger.next();
  }

  ngOnDestroy() {
    this.dtTrigger.unsubscribe();
  }

  rerender() {
    this.selectfromdate = null;
    this.selecttodate = null;
    this.onchangeinput(null)
    if (this.dtElement && this.dtElement.dtInstance) {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        // Destroy the table first
        dtInstance.destroy();
        // Call the dtTrigger to rerender again
        this.dtTrigger.next();
      });
    }
  }

}
