<mat-card class="content-wrapper">
    <mat-card-header class="title_center">
        <a class="btn btn-info btn-sm btn-sm" data-toggle="modal" style="float: left;" data-target="#fancymodal"
            title="Add Fancy Details"><i class="fa fa-plus"></i>
        </a>
        TK List
        <a class="btn btn-warning btn-sm" style="float: right;" title="Reload Fancy Records" (click)="getFancyList()"><i
                class="fa fa-refresh fa-spin"></i>
        </a>
    </mat-card-header>
    <mat-card-content>
        <div class="row">
            <div class="col-sm-3 col-xs-3 p-10">
                <input type="search" placeholder="Select Date..." class="form-control" name="From date" bsDatepicker
                    [(ngModel)]="selectfromdate" autocomplete="off" (ngModelChange)="onchangeinput($event)"
                    [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD', containerClass: 'theme-default', isAnimated: true }">
            </div>
            <div class="col-sm-3 col-xs-3 p-10">
                <ng-select  [(ngModel)]="selectevent" (ngModelChange)="getFancyList()">
                    <ng-option value="" selected="selected">---- Select Eventname ----</ng-option>
                    <ng-option *ngFor="let high of highlights;trackBy:trckbyeventid" [value]="high.eventid">{{high.eventname}} /
                        {{high.eventdate.split("T")[0]}}
                    </ng-option>
                </ng-select>
            </div>
            <div class="col-sm-3 col-xs-3 p-10">
                <select class="form-control" [(ngModel)]="selectedStatus" (ngModelChange)="getFancyList()">
                    <option *ngFor="let option of options" [value]="option.status">{{option.type}}</option>
                </select>
            </div>

            <div class="col-sm-3 col-xs-3 p-10">
                
                <a *ngIf="selectevent  && selectedStatus!=0" class="btn btn-warning btn-sm m-1" title="Map Sky Event" (click)="updateAllSkyFancyResult(selectevent)">
                    Update All
                </a>
                <a *ngIf="selectevent  && selectedStatus!=0" class="btn btn-warning btn-sm m-1" title="Map Sky Event" (click)="verifyAllSkyFancyResult(selectevent)" >
                    Verify All
                </a>
                <a *ngIf="selectedItems.length >0 && selectedStatus==0" class="btn btn-danger btn-sm" data-toggle="modal" (click)="cancelmultifancy()" title="Cancel Fancy"
                    ><span>Cancel ({{selectedItems.length}})</span>
               </a>
            </div>
        </div>
        <hr>
        <div class="table-responsive">
            <table class="table table-striped table-bordered table-sm row-border hover" id="fancytable" datatable
                [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead>
                    <tr class="heading">
                        <th [style.display]="selectedStatus == 0 ? '' : 'none'">
                            <input type="checkbox" [(ngModel)]="isAllSelected" name="" id="" (change)="selectAll($event.target.checked)">
                        </th>
                        <th>Id</th>
                        <th>Event ID</th>
                        <th>Name</th>
                        <th>{{(selectedStatus!='0')?"Settled":"Settle Session"}}</th>
                        <th>Eventname</th>
                        <th>Status</th>
                        <th>Action </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let fancy of fancyList;let index=index;trackBy:trackfancyid">
                        <td [style.display]="selectedStatus == 0 ? '' : 'none'">
                            <input type="checkbox" [(ngModel)]="isAllSelected" name="" id="" (change)="toggleSelection(fancy, $event.target.checked)">
                        </td>
                        <td>{{fancy.Id}}</td>
                        <td>{{fancy.Eventid}}</td>
                        <td style="text-align: center;font-weight: 700;">{{fancy.Fancy}}<br><span
                                style="font-size: 10px">{{fancy.Date.split("T")[0]}}</span></td>
                        <td>
                            <div style="display: flex;">
                                <div class="col-xs-10">
                                    <input style="min-width:85px;" type="number" placeholder="Result" class="form-control" name="Settle"
                                        [(ngModel)]="fancy.Result" autocomplete="off" [disabled]="fancy.Status!=0">
                                </div>
                                <div style="margin: 3px;">
                                    <a class="btn btn-warning btn-sm" title="Settle Fancy" (click)="fancyinfodata(fancy)" data-toggle="modal" data-target="#Confirmsettlemodal"
                                        *ngIf="fancy.Status==0"><span>Settle</span></a>
                                    <a class="btn btn-warning btn-sm" title="Reverse Fancy" *ngIf="fancy.Status==1 || fancy.Status==2"
                                        (click)="fancyinfodata(fancy)" data-toggle="modal" data-target="#Confirmreversemodal"><span>Reverse</span>
                                    </a>
                                </div>
                            </div>
                        </td>
                        <td style="text-align: center;font-weight: 700;">{{fancy.Eventname}}<br><span
                                style="font-size: 10px">{{fancy.Date}}</span></td>
                        <td><a class="btn btn-sm"
                                [ngClass]="{'btn-info':(fancy.Status==0),'btn-success':(fancy.Status==2 || fancy.Status==1)}"
                                title="Status"><span>{{(fancy.Status==0)?'ACTIVE':'SETTLED'}}</span></a>
                                <a class="btn btn-sm btn-info" *ngIf="fancy.isBet==1" [class.disabled]="fancy.isBet==1 && fancy.gameId!=null"
                                title="Status"><span>VRNL</span></a>
                            </td>
                        <td>
                            <a (click)="openreversesettlemodal(fancy.gameId)" class="btn btn-danger btn-sm" title="reverse settle"   data-toggle="modal" data-target="#settlereverse"
                            *ngIf="(fancy.Status==2 || fancy.Status==1) && getusertype==2"><span>R</span>
                          </a>
                            <a class="btn btn-info btn-sm" title="Edit Fancy" *ngIf="fancy.Status==0"><span><i
                                        class="fa fa-edit" aria-hidden="true" (click)="fancyinfodata(fancy)"></i></span>
                            </a>
                            <a class="btn btn-info btn-sm" title="Verify Fancy" *ngIf="fancy.CompitionName!=null"
                                (click)="verifyfancyresult(fancy)"><span>{{fancy.CompitionName}}</span>
                            </a>
                            <a class="btn btn-info btn-sm" title="Verify Fancy" *ngIf="fancy.CompitionName==null"
                                (click)="verifyfancyresult(fancy)"><span>Verify</span>
                            </a>
                            <a class="btn btn-danger btn-sm" title="Update Fancy" *ngIf="(fancy.Status==2 || fancy.Status==1)&&fancy.CompitionName==null"
                                (click)="updateUnverified(fancy)"><span>Update</span>
                            </a>
                            <a class="btn btn-danger btn-sm" data-toggle="modal" data-target="#Confirmcancelmodal" title="Cancel Fancy" *ngIf="fancy.Status==0"
                                (click)="fancyinfodata(fancy)"><span>Cancel</span>
                            </a>
                        </td>
                    </tr>

                </tbody>
            </table>

        </div>
        <!-- Fancy Add -->
        <div id="fancymodal" class="modal fade" role="dialog" aria-labelledby="fancymodal" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <span>Fancy Details</span>
                        <button type="button" class="close" data-dismiss="modal">
                            <div class="close_new"><i class="fa fa-times-circle"></i> </div>
                        </button>
                    </div>

                    <div class="modal-body">
                        <div class="popup_form_row" *ngIf="AddFancyForm">
                            <form [formGroup]="AddFancyForm" (ngSubmit)="addSessions()">
                                <div>
                                    <label> Fancy Name : </label>
                                    <input type="text" class="form-control" formControlName="fancyname">
                                </div>
                                <div>
                                    <label> Event Name : </label>
                                    <input type="text" class="form-control" formControlName="eventname">
                                </div>
                                <div>
                                    <label> Event Date : </label>
                                    <input type="text" class="form-control" formControlName="date">
                                </div>
                                <div>
                                    <label> EventId : </label>
                                    <input type="text"
                                        onkeypress="return ((event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 8 || (event.charCode >= 48 && event.charCode <= 57))"
                                        class="form-control" formControlName="eventid">
                                </div>
                                <div class="popup_col_12 button-row">
                                    <button mat-raised-button color="primary" [disabled]="AddFancyForm.invalid"
                                        type="submit">Add</button>
                                    <button mat-raised-button color="primary" data-dismiss="modal" (click)="resetForm()"
                                        type="button">Cancel</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Delete all Confirm Info -->
        <div id="Confirmdeleteallmodal" class="modal fade" role="dialog" aria-labelledby="Confirmmodal"
            aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <span>Confirm Box</span>
                        <button type="button" class="close" data-dismiss="modal">
                            <div class="close_new"><i class="fa fa-times-circle"></i> </div>
                        </button>
                    </div>

                    <div class="modal-body">
                        <div class="popup_form_row">
                            <label>Are you sure you want to delete All Fancies ?</label>
                        </div>
                        <div class="popup_col_12 button-row">
                            <button mat-raised-button color="primary" type="button">Yes</button>
                            <button mat-raised-button color="primary" data-dismiss="modal" type="button">No</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Delete Confirm Info -->
        <div id="Confirmdeletemodal" class="modal fade" role="dialog" aria-labelledby="Confirmmodal" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <span>Confirm Box</span>
                        <button type="button" class="close" data-dismiss="modal">
                            <div class="close_new"><i class="fa fa-times-circle"></i> </div>
                        </button>
                    </div>

                    <div class="modal-body">
                        <div class="popup_form_row">
                            <label>Are you sure you want to delete Fancy ?</label>
                        </div>
                        <div class="popup_col_12 button-row">
                            <button mat-raised-button color="primary" type="button">Yes</button>
                            <button mat-raised-button color="primary" data-dismiss="modal" type="button">No</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Settle Confirm Info -->
        <div id="Confirmsettlemodal" class="modal fade" role="dialog" aria-labelledby="Confirmmodal" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <span>Confirm Settle</span>
                        <button type="button" class="close" data-dismiss="modal">
                            <div class="close_new"><i class="fa fa-times-circle"></i> </div>
                        </button>
                    </div>
        
                    <div class="modal-body">
                        <div class="popup_form_row">
                            <label>Event Name : <strong>{{fancydata?.Eventname}}</strong></label><br>
                            <label>Event Date : <strong>{{fancydata?.Date}}</strong></label><br>
                            <label>Fancy Name : <strong>{{fancydata?.Fancy}}</strong></label><br>
                            <label>Entered Result : <strong>{{(fancydata?.Result==null)?'Please Enter Result':fancydata?.Result}}</strong></label>
                        </div>
                        <div class="popup_form_row">
                            <label>Are you sure you want to settle Fancy with result <strong>{{fancydata?.Result}}</strong>?</label>
                        </div>
                        <div class="popup_col_12 button-row">
                            <button mat-raised-button color="primary" type="button" (click)="Updateresult(fancydata)">Yes</button>
                            <button mat-raised-button color="primary" data-dismiss="modal" type="button">No</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- reverse Confirm Info -->
        <div id="Confirmreversemodal" class="modal fade" role="dialog" aria-labelledby="Confirmmodal" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <span>Confirm Box</span>
                        <button type="button" class="close" data-dismiss="modal">
                            <div class="close_new"><i class="fa fa-times-circle"></i> </div>
                        </button>
                    </div>
        
                    <div class="modal-body">
                        <div class="popup_form_row">
                            <label>Are you sure you want to reverse Fancy ?</label>
                        </div>
                        <div class="popup_col_12 button-row">
                            <button mat-raised-button color="primary" type="button" (click)="reversefancy(fancydata)">Yes</button>
                            <button mat-raised-button color="primary" data-dismiss="modal" type="button">No</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- cancel Confirm Info -->
        <div id="Confirmcancelmodal" class="modal fade" role="dialog" aria-labelledby="Confirmmodal" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <span>Confirm Box</span>
                        <button type="button" class="close" data-dismiss="modal">
                            <div class="close_new"><i class="fa fa-times-circle"></i> </div>
                        </button>
                    </div>
        
                    <div class="modal-body">
                        <div class="popup_form_row">
                            <label>Are you sure you want to cancel Fancy ?</label>
                        </div>
                        <div class="popup_col_12 button-row">
                            <button mat-raised-button color="primary" type="button" (click)="cancelfancy(fancydata)">Yes</button>
                            <button mat-raised-button color="primary" data-dismiss="modal" type="button">No</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

           <!-- reverse and add runs-->
           <div id="settlereverse" class="modal fade" role="dialog" aria-labelledby="Confirmmodal" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <span> Reverse Settle Fancy</span>
                        <button type="button" class="close" data-dismiss="modal">
                            <div class="close_new"><i class="fa fa-times-circle"></i> </div>
                        </button>
                    </div>

                    <div class="modal-body">
                        <div class="popup_form_row">
                            <label>Enter Runs : </label>
                        </div>

                        <div class="popup_col_12 button-row ">
                            <form [formGroup]="runsForm" class="runform">
                                <input type="text" class="form-control" formControlName="runs" [(ngModel)]="runs">
                                <button class="btnnewcls" mat-raised-button color="primary" type="button"
                                    (click)="reverseSetteledFancy()">Update</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>